import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Lenders from '../../Pages/Admin/Lenders/Lenders';
import Details from '../../Pages/Admin/Lenders/Details';

const Lender = () => {
  return (
    <Routes>
        <Route path='/list' element={<Lenders />} />
        <Route path='/details/:lenderCode' element={<Details />} />
    </Routes>
  )
}

export default Lender;
