import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Batterys from '../../Pages/Oem/Battery/Oem';
import Details from '../../Pages/Oem/Battery/Details';

const Battery = () => {
  return (
    <Routes>
        <Route path='/list' element={<Batterys />} />
        <Route path='/details/:batteryId' element={<Details />} />
    </Routes>
  )
}

export default Battery;
