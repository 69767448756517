import React, { useState } from 'react';
import { Collapse, Card, CardBody, CardHeader, Button, Container, Row, Col, Accordion, AccordionItem, AccordionHeader, AccordionBody } from 'reactstrap';

const Accordions = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <Container>
    { items?.map((item,index)=> (
        <Accordion open={activeIndex} toggle={toggle}>
    <AccordionItem>
      <AccordionHeader targetId={index}>
        {item?.title}
      </AccordionHeader>
      <AccordionBody accordionId={index}>
        {activeIndex === index && item?.content}
      </AccordionBody>
    </AccordionItem>
  </Accordion>
    )) }
</Container>
  );
};

export default Accordions;
