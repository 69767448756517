import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { formatDate } from '../../utils/helper';

const DateRange = (props) => {

  const {identifier, handleInput} = props;

  const handleDateRangeChange = (date) => {
    let fromDate;
    let toDate;
    if(date){
      fromDate = formatDate(date[0]);
      toDate = formatDate(date[1]);
    }
    handleInput(identifier, {fromDate, toDate});
  }

  const disabledDate = (date) => {
    return date > new Date();
  };

  return (
    <DateRangePicker 
      format="dd-MM-yyyy" 
      block 
      showOneCalendar 
      placeholder='DD-MM-YYYY to DD-MM-YYYY'
      onChange={handleDateRangeChange}
      disabledDate={disabledDate}
    />
  )
}

export default DateRange;