import React from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { formatDate } from '../../utils/helper';

const columns = (permission, handleSendToLender, applicationId, loanId) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>Lender Name</span>,
            selector: row => (row?.name || '-'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Lender Code</span>,
            selector: row => row?.code || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Child Loan ID</span>,
            selector: row => (row?.childLoanId || '-'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Lender Loan ID</span>,
            selector: row => (row?.lenderLoanId || '-'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Approved Amount</span>,
            selector: row => row?.mobile || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row?.application?.status) {
                    case "Re-open":
                        return <span className="badge badge-soft-info"> {row?.status || '-'} </span>;
                    case "On-Hold":
                        return <span className="badge badge-soft-secondary"> {row?.status || '-'} </span>;
                    case "Closed":
                        return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
                    case "INPROGRESS":
                        return <span className="badge badge-soft-warning"> {row?.status || '-'} </span>;
                    case "Open":
                        return <span className="badge badge-soft-primary"> {row?.status || '-'} </span>;
                    case "COMPLETE":
                        return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => (formatDate(new Date(row?.createdAt)) || '-'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: false,
            cell: (row) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            {permission?.CUSTOMERS?.DETAILS?.SEND_TO_LENDER && !row?.applicationSent && 
                                <DropdownItem onClick={() => handleSendToLender(applicationId, row?.code, loanId)}>
                                    <i className="ri-eye-fill align-bottom me-2 text-muted" /> Send Email
                                </DropdownItem>
                            }
                            <DropdownItem className='edit-item-btn'><i className="ri-pencil-fill align-bottom me-2 text-muted"></i>Edit</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },
    ];

    return data;
}

export default columns;
