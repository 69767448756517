import { all } from 'redux-saga/effects';
import adminSaga from './admin/saga'; // Import your sagas
import userSaga from './user/saga';
import applicationSaga from './application/saga';
import loanSaga from './loan/saga';
import oemSaga from './oem/saga';
import DashboardSaga from './dashboard/saga';


export default function* rootSaga() {
    yield all([
        adminSaga(),
        userSaga(),
        applicationSaga(),
        loanSaga(),
        oemSaga(),
        DashboardSaga(),
        // Add other sagas here
    ]);
}