import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Table from "../../../components/Common/Table";
import productColumns from "./ProductTableColumn";
import CreateProduct from "./CreateProduct";
import useClass from "../../../hooks/useClass";
import Filters from "../../../components/Common/Filters";
import { getProductList } from "../../../store/admin/adminData";
import { useSelector } from "react-redux";

const Products = () => {
  document.title = "Products | TapFin";

  const { permission, dispatch} = useClass();
  const columns = productColumns(permission);
  const { list, totalList, loading } = useSelector((state) => (state?.product));
  const [createProduct, setCreateProduct] = useState(false);
  const [pagination, setPagination] = useState({ page: 1, perPage: 20 });


  const getProduct = (params) => {
    const { pagination, searchText, dateRange } = params;
    dispatch(getProductList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange}));
  };


  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Products" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="d-flex justify-content-between">
                  <h5 className="card-title mb-0">Product List</h5>
                  <Filters
                    fetchDataFromServer={getProduct}
                    pagination={pagination}
                    setPagination={setPagination}
                    showCreateButton={permission?.PRODUCTS?.LIST?.CREATE_NEW_PRODUCT}
                    onCreateButtonClick={() => setCreateProduct(true)}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    data={list}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    paginationTotalRows={totalList}
                    columns={columns}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateProduct isOpen={createProduct} setIsOpen={setCreateProduct} />
      </div>
    </>
  );
};

export default Products;
