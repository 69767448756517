import { createSlice } from "@reduxjs/toolkit";

export const customerPayments = createSlice({
    name: 'customerPayments',
    initialState: {
        loading: false,
        error: null,
        totalList: 0,
        paymentDetails:[],
    },
    reducers: {
        getCustomerPayments: (state, action) => {
            state.loading = true;
        },
        updateCustomerPayment : (state, action) => {
            state.loading = true;
        },
        updateCustomerPaymentSuccess : (state, action) => {
            state.loading = false;
            state.paymentDetails = [action?.payload,...state.paymentDetails]
        },

        getCustomerPaymentsSuccess: (state, action) => {
            state.loading = false;
            state.paymentDetails = action?.payload;
        },
        getCustomerPaymentsError: (state, action) => {
            state.loading = false;
            state.error = action?.payload;
        }
    }
});





export const { 
    getCustomerPayments,
    updateCustomerPayment,
    updateCustomerPaymentSuccess,
    getCustomerPaymentsSuccess,
    getCustomerPaymentsError
} = customerPayments?.actions;


export const customerPaymentsReducer = customerPayments?.reducer;