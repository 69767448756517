import React, { useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../components/Common/Table';
import CustomerTableColumns from './CustomerTableColumns';
import useClass from '../../hooks/useClass';
import Filters from '../../components/Common/Filters';
import { getCustomerApplicationList } from '../../store/application/customer';
import { useSelector } from 'react-redux';

const Customers = () => {
    document.title = "Customers | TapFin";

    const {permission, dispatch} = useClass();
    const { list, totalList, loading } = useSelector((state) => (state?.customer));
    const columns = CustomerTableColumns(permission);
    const [pagination, setPagination] = useState({page: 1, perPage: 20});

    const getCustomerList = (params) => {
        const { pagination, searchText, dateRange } = params;
        dispatch(getCustomerApplicationList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange}))
    }

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Customers" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-between'>
                                    <h5 className="card-title mb-0">Customers List</h5>
                                    <Filters
                                        fetchDataFromServer={getCustomerList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table 
                                        data={list} 
                                        loading={loading}
                                        columns={columns}
                                        paginationTotalRows={totalList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Customers;