import ReactFlow, 
  {  
    Controls, 
    Background,  
    applyNodeChanges,
    applyEdgeChanges, 
    addEdge,
    Panel,
    useNodesState,
    useEdgesState
  } from 'reactflow';
import 'reactflow/dist/style.css';
import { useCallback, useEffect, useState} from 'react';
import Edge from './Edge';
import '../onboarding-system.scss'
import Node from './Node';
import ConnectionLine from './ConnectionLine';
import { Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import NodeStepType from './NodeTypes'
const nodeTypes = {
  'resizer': Node,
  'stepNode':NodeStepType
};


const edgeType = {
  'custom-edge': Edge
};

const initialNodes = [
  // { id: 'a', type: 'resizer', position: { x: 0, y: 0 }, data: { label: 'Node A' } },
  // { id: 'b', type: 'resizer', position: { x: 0, y: 100 }, data: { label: 'Node B' } },
  // { id: 'c', type: 'resizer', position: { x: 0, y: 200 }, data: { label: 'Node C' } },
];



const initialEdges = [
  // { id: 'a->b', type: 'custom-edge', source: 'a', target: 'b', animated: true },
  // { id: 'b->c', type: 'custom-edge', source: 'b', target: 'c', animated: true },
];


const Flow = () => {

 
  const [nodes, setNodes,onNodesChange] = useNodesState([]);
  const [edges, setEdges,onEdgesChange] = useEdgesState([]);
  const [nodeId, setNodeId] = useState('0');
  const location = useLocation();
  const productCode = location.state?.productCode;

  // const onNodesChange = useCallback((changes) => {
  //   setNodes((nds) => applyNodeChanges(changes, nds))
  // },[]);

  // const onEdgesChange = useCallback((changes) => {
  //   setEdges((eds) => applyEdgeChanges(changes, eds));
  // },[]);

  const onConnect = useCallback((connection) => {
    const edge = { ...connection, type: 'custom-edge', animated: true };
    setEdges((eds) => addEdge(edge, eds));
  },[setEdges]);
  
  const deleteNode = (id) =>{
    setNodes((prev)=>prev.filter((node)=>node.id!==id))
  }
  const createNewNode = () => {
    
    const newNode = {id: nodeId,
      data:{
      delNode:deleteNode,
      id:nodeId,
      label: `${nodeId}`
    } ,type: "stepNode", position: {x: -50, y: 100}};
    setNodeId((prev) => (`${parseInt(prev)+1}`));
    setNodes((prev) => ([...prev, newNode]));
  }

  return (
    <div style={{height: '90vh'}}>
      <ReactFlow 
        className="react-flow-node-resizer-example"
        nodes={nodes} 
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        edgeTypes={edgeType}
        nodeTypes={nodeTypes}
        connectionLineComponent={ConnectionLine}
        fitView
      >
        <Background />
        <Controls />
        <Panel>
          <Button className='success' onClick={createNewNode}>+ Add Node</Button>
        </Panel>
      </ReactFlow>
    </div>
  );
}

export default Flow;
 