import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import NonAuthLayout from "../Layout/NonAuthLayout";
import VerticalLayout from "../Layout/VerticalLayout/index";
import { AuthProtected } from "./AuthProtected";
import { authProtectedRoutes, publicRoutes } from "./routes";
import { Spinner } from "reactstrap";


const Index = () => {

  return (
    // <Suspense fallback={<Spinner>Loading...</Spinner>}>
    <Routes>
      <Route>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Route>

      <Route>
          {authProtectedRoutes.map((route, idx) => {
            return(
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  <VerticalLayout>{route.component}</VerticalLayout>
                </AuthProtected>
                }
              key={idx}
              exact={true}
            />)
          })}
      </Route>
    </Routes>
    // </Suspense>
  );
};

export default Index;
