import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = ({teamNames,teamCounts}) => {

  const PieChartData = {
    series: teamNames,
    options: {
      labels: teamCounts,
      colors: ["rgb(61, 142, 248)", "rgb(17, 196, 110)",  "#f1b44c", "#f46a6a", "rgb(239, 242, 247)"],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 240,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
  }

  return(
    <React.Fragment>
        <ReactApexChart
          options={PieChartData.options}
          series={PieChartData.series}
          type="pie"
          height="320"
          className="apex-charts"
        />
      </React.Fragment>
  )
}

export default PieChart;