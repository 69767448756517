import React, { useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../../components/Common/Table';
import taskColumns from './Columns';
import useClass from '../../../hooks/useClass';
import Create from './Create';
import Filters from '../../../components/Common/Filters';
import { getChargerList } from '../../../store/oem/oemData'; // Adjust to charger list action
import { useSelector } from 'react-redux';

const Charger = () => {
    document.title = "Charger | TapFin"; // Set appropriate title

    const { permission, dispatch } = useClass();
    const columns = taskColumns(permission); // Assuming columns function works similarly for chargers
    const { list, totalList, loading } = useSelector((state) => (state?.charger)); // Using charger slice
    const [createNewTask, setCreateNewTask] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, perPage: 20 });

    const fetchTaskList = (params) => {
        const { pagination, searchText, dateRange } = params;
        dispatch(getChargerList({ page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange }));
    }

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Charger" /> 
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-between'>
                                    <h5 className="card-title mb-0">Charger</h5> 
                                    <Filters
                                        fetchDataFromServer={fetchTaskList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        showCreateButton={permission?.CHARGER?.LIST?.CREATE_NEW_CHARGER}
                                        onCreateButtonClick={() => { setCreateNewTask(true) }}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        data={list}
                                        loading={loading}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columns={columns}
                                        paginationTotalRows={totalList}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Create 
                isOpen={createNewTask} 
                setIsOpen={setCreateNewTask} 
            />
        </>
    );
};

export default Charger;
