import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Users from '../../Pages/Admin/Users/Users';
import Details from '../../Pages/Admin/Users/Details';

const User = () => {
  return (
    <Routes>
        <Route path='/list' element={<Users />} />
        <Route path='/details/:userId' element={<Details />} />
    </Routes>
  )
}

export default User;
