import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import useClass from "../../../hooks/useClass";
import LoadingButton from "../../../components/Common/LoadingButton";
import ConfigCanban from "../../../components/Config-Canban/ConfigCanban";
import { productSchemeConfigFields } from "../../../constants/constants";
import { useSelector } from "react-redux";
import { getSchemeDetails, updateSchemeConfig } from "../../../store/admin/adminData";

const Details = () => {

  const { permission, dispatch } = useClass()
  const { details, loading } = useSelector((state) => (state?.productScheme));
  const { code } = useParams();
  const [form, setForm] = useState({});
  const [selectedConfigFields, setSelectedConfigFields] = useState([]);

  useEffect(() => {
    dispatch(getSchemeDetails(code));
  }, []);

  useEffect(() => {
    if(details){
      setForm(details?.data || {});
    }
  }, [details])

  const handleProductSchemeChange = (key, value) => {

  }

  const handleConfigInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleProductSchemeConfigUpdate = async () => {
    dispatch(updateSchemeConfig({form:{data:form}, code}))
  }


  return (
    <div>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="product_scheme" breadcrumbItem="Product Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader className="d-flex justify-content-between">
                  <div className="section-heading">Scheme Details</div>
                  {/* <div className="form-check form-switch form-switch-md">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      checked={details?.isActive || false}
                      onChange={(e) => handleProductSchemeChange("isActive",e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      style={details?.isActive ? { color: "green" } : { color: "red" }}
                    >
                      {details?.isActive ? "Active" : "In-Active"}
                    </label>
                  </div> */}
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Scheme Name</Row>
                      <Row className="data-value me-4">{details?.name}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Scheme Code</Row>
                      <Row className="data-value me-4">
                        {details?.code}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created Date</Row>
                      <Row className="data-value me-4">
                        {new Date(details?.createdAt).toLocaleDateString()}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Updated Date</Row>
                      <Row className="data-value me-4">
                      {new Date(details?.updatedAt).toLocaleDateString()}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <ConfigCanban 
              fields={productSchemeConfigFields}
              form={form} 
              setForm={setForm}
              handleInput={handleConfigInput}
              setSelectedConfigFields={setSelectedConfigFields}
            />
          </Row>
          {permission?.PRODUCT_SCHEME?.DETAILS?.UPDATE_PRODUCT_SCHEME_DETAILS && <Row className="mt-4">
            <Col className="d-flex justify-content-center gap-4">
              <LoadingButton
                color={"success"}
                onClick={handleProductSchemeConfigUpdate}
                buttonContent={"Update"}
                isLoading={loading}
              />
            </Col>
          </Row>}
        </Container>
      </Row>
    </div>
  );
};

export default Details;
