import DataTable from "react-data-table-component";

const Table = ({ data, loading, pagination, setPagination, columns, paginationTotalRows=20, showPagination=true}) => {

  const handlePageChange = (page) => {
    setPagination((prev) => ({
      ...prev,
      page: page,
    }));
  };

  const handleRowPerPageChange = (row) => {
    setPagination((prev) => ({
      ...prev,
      perPage: row,
    }));
  };

  return (
    <DataTable
      // title={label}
      columns={columns}
      data={data}
      pagination={showPagination}
      paginationPerPage={pagination?.perPage}
      paginationRowsPerPageOptions={[5, 10, 20]}
      onChangePage={handlePageChange}
      progressPending={loading}
      paginationServer={showPagination}
      paginationTotalRows={paginationTotalRows}
      onChangeRowsPerPage={handleRowPerPageChange}
    />
  );
};

export default Table;
