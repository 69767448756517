import React from "react";
import { Link } from "react-router-dom";


const columns = (permission) => {
  const data = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row?.index,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Customer Code</span>,
      selector: (row) => row?.customerCode, 
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Amount</span>,
      selector: (row) => row?.amount,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Partner Code</span>,
      selector: (row) => row?.partnerCode,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Product Type</span>,
      selector: (row) => row?.productType,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Refund Date</span>,
      selector: (row) =>row?.refundAt,
      sortable: false,
    },
    {
        name: <span className="font-weight-bold fs-13">Payment Mode</span>,
        selector: (row) =>row?.paymentMode,
        sortable: false,
    },
    {
        name: <span className="font-weight-bold fs-13">Source</span>,
        selector: (row) =>row?.source,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Penalty Repaid</span>,
        selector: (row) =>row?.penaltyRePaid,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Pending Amount</span>,
        selector: (row) =>row?.pendingAmount,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Status</span>,
        selector: (row) =>row?.status,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Transaction Id</span>,
        selector: (row) =>row?.transactionId,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">UTR Number</span>,
        selector: (row) =>row?.utrNo,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Total Due</span>,
        selector: (row) =>row?.totalDue,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Created At</span>,
        selector: (row) =>new Date(row?.createdAt).toLocaleDateString(),
        sortable: false,
     },
     
  ];

  return data;
}

export default columns;