import React, {useEffect, useState} from 'react';
import withRouter from "../../components/Common/withRouter";
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import { useSelector } from 'react-redux';
import sidebarData from "./SidebarData";

const Layout = (props) => {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const leftSideBarType = 'default';
  const [menu, setMenu] = useState([]);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const permissionPair = useSelector((state) => (state?.permissionPair?.permissions));

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      // dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      // dispatch(changeSidebarType("default", isMobile));
    }
  };

  useEffect(() => {
    sidebarItems();
  }, [])

  const sidebarItems = () => {
    let arr =[];

    sidebarData?.forEach((module) => {
      let subItem = []
      let item = {};
      if(module.subItem){
        module?.subItem.forEach((subModule) => {
          if(permissionPair[subModule.subModuleCode]){
            subItem = [...subItem, subModule];
          }
        })
        if(subItem.length > 0){
          item = module;
          item.subItem = subItem;
        }
      } else {
        if(permissionPair[module.moduleCode]){
          item = module;
        }
      }
      if(Object.keys(item).length > 0){
        arr = [...arr, item];
      }
    })

    setMenu(arr);
    setShowSideMenu(true);
  }

  return (
    <>
      <div id="layout-wrapper">
        <Header toggleMenuCallback={toggleMenuCallback} />
        {showSideMenu && <Sidebar
          theme={'DARK'}
          type={leftSideBarType}
          menu={menu}
          isMobile={isMobile}
        />}
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
    </>
  );
};

export default withRouter(Layout);
