import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row.index,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Scheme Name</span>,
            selector: row =>(permission?.PRODUCT_SCHEME?.DETAILS?.VIEW_PRODUCT_SCHEME_DETAILS ? <Link to={`/product_scheme/details/${row?.code}`}>{row.name}</Link> : row?.name),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Scheme Code</span>,
            selector: row => (row.code),
            sortable: false
        },
        {
            name: <span className="font-weight-bold fs-13">Created By</span>,
            selector: (row) => row?.createdBy,
            sortable: false,
          },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row =>new Date(row?.createdAt).toLocaleDateString() ,
            sortable: false
        }
    ];

    return data;
}

export default columns;
