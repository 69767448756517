import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import logo from '../../assets/images/TAPFIN-1.png';
import Oauth from "./Oauth/Oauth";
import useClass from "../../hooks/useClass";

const Login = (props) => {

  document.title = "Login";
  const error = '';
  const {server, toaster, navigate} = useClass()
  const [tapfinUser, setTapfinUser] = useState(false)
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      server.validateLoginCredentials(values).then((response) => {
        if(response?.data?.success){
          localStorage.setItem('token', response?.data?.data?.token);
          navigate('/');
        } else {
          toaster.show(true, 'Please Enter Valid Details');
        }
      }).catch((error) => {
        toaster.show(true, error?.message);
      })
    }
  });

  const isValidTapfinEmail = (email) => {
    const pattern = /^[a-zA-Z0-9._%+-]+@tapfin\.in$/;
    return pattern.test(email) && email !== 'tech@tapfin.in';
}

  useEffect(() => {
   const result = isValidTapfinEmail(validation.values.email);
    setTapfinUser(result)
    document.body.className = "bg-pattern";
    return () => {
      document.body.className = "";
    };
  });

  return (
    <>
    
    <div className="bg-overlay"></div>
    <div className="account-pages my-5 pt-5">
      <Container>
        <Row className="justify-content-center">
          <Col lg={6} md={8} xl={4}>
            <Card>
              <CardBody className="p-4">
                <div>
                  <div className="text-center">
                    <Link to="/">
                      <img
                        src={logo}
                        alt=""
                        height="24"
                        className="auth-logo logo-dark mx-auto"
                      />
                      <img
                        src={logo}
                        alt=""
                        height="24"
                        className="auth-logo logo-light mx-auto"
                      />
                    </Link>
                  </div>
                  <h4 className="font-size-18 text-muted mt-2 text-center">
                    Welcome Back !
                  </h4>
                  <p className="mb-5 text-center">
                    Sign in to continue with TapFin.
                  </p>
                  <Form
                    className="form-horizontal"
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                  >
                    {error ? <Alert color="danger"><div>{error}</div></Alert> : null}
                    <Row>
                      <Col md={12}>
                        <div className="mb-4">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter Email"
                          type="email"
                          autoComplete="off"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                        ) : null}
                        </div>
                        {!tapfinUser && <div className="mb-4">
                          <Label className="form-label">Password</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid"><div> {validation.errors.password} </div></FormFeedback>
                          ) : null}
                        </div>}

                        <Row>
                          <Col>
                            {/* <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-label form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div> */}
                          </Col>
                          {/* <Col className="col-7">
                            <div className="text-md-end mt-3 mt-md-0">
                              <Link
                                to="/auth-recoverpw"
                                className="text-muted"
                              >
                                <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                            </div>
                          </Col> */}
                        </Row>
                        <div className="d-flex  justify-content-between mt-4 gap-2 flex-grow-1">
                         <Oauth tapfinUser={tapfinUser}/>
                           <div style={{ width: tapfinUser ? '' : '45%' }} className="d-grid  mt-4" >
                             {!tapfinUser && <button
                             className="btn btn-primary waves-effect waves-light"
                             type="submit"
                             
                            >
                             Log In
                            </button>}
                          </div>
                         
                        </div>
                        
                        <div className="mt-4 text-center">
                    </div>

                      </Col>
                    </Row>
                  </Form>
                </div>
              </CardBody>
            </Card>
            <div className="mt-5 text-center">
              {/* <p className="text-white-50">
                Don't have an account ?{" "}
                <Link to="/register" className="fw-medium text-primary">
                  {" "}
                  Register{" "}
                </Link>{" "}
              </p> */}
              <p className="text-white-50">
                © {new Date().getFullYear()} TapFin. Crafted with{" "}
                <i className="mdi mdi-heart text-danger"></i> by TapFin Tech.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
   
  </>
  );
};

export default (Login);

Login.propTypes = {
  history: PropTypes.object,
};
