import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Products from '../../Pages/Admin/Products/Products';
import Details from '../../Pages/Admin/Products/Details';


const Product = () => {
  return (
    <Routes>
        <Route path='/list' element={<Products />} />
        <Route path='/details/:productCode' element={< Details/>} />
    </Routes>
  )
}

export default Product;
