import React from 'react';
import { Link } from 'react-router-dom';
import { formatDate } from '../../../utils/helper';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row.index,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Role ID</span>,
            selector: row => (permission?.ROLES?.DETAILS?.VIEW_ROLE_DETAILS ? <Link to={`/roles/details/${row?.roleId}`}>{row.roleId}</Link> : row?.roleId),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Role</span>,
            selector: row => row.roleName,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Description</span>,
            selector: row => (row.description || '-'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Is Active</span>,
            sortable: false,
            selector: (row) => {
                switch (`${row?.isActive}`) {
                    case "false":
                        return <span className="badge badge-soft-danger"> {`${row?.isActive}` || '-'} </span>;
                    case "true":
                        return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createDate).toLocaleDateString()|| '-',
            sortable: false
        }
    ];

    return data;
}

export default columns;
