import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import useClass from "../../../hooks/useClass";
import { useNavigate } from "react-router-dom";

const CreatePartner = (props) => {
  const { adminServer, toaster } = useClass();
  const { isOpen, setIsOpen } = props;
  const [partnerName, setPartnerName] = useState("");
  const [partnerCode, setPartnerCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleModelClose = () => {
    setIsOpen(false);
  };

  const handlePartnerCreation = async () => {
    if (validateForm()) {
      await createPartner({ partnerName, partnerCode });
    } else {
      toaster.show(true, "Please Fill All Details to create partner.");
    }
  };

  const createPartner = async (payload) => {
    try {
      setLoading(true);
      const response = await adminServer.createPartner(payload);
      const partnerCode = response?.data?.data?.code;
      setLoading(false);
      setIsOpen(false);
      navigate(`/partners/details/${partnerCode}`);
    } catch (error) {
      toaster.show(true, error?.message);
    }
  };

  const validateForm = () => {
    return partnerName.trim() !== "";
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen);
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Create Partner
          </h5>
          <button
            type="button"
            onClick={handleModelClose}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div>
            <label className="form-label">Partner Name</label>
            <input
              className="form-control"
              type="text"
              value={partnerName}
              onChange={(e) => {
                setPartnerName(e.target.value);
              }}
              placeholder="Partner Name"
            />
          </div>
          <div className="mt-4">
            <label className="form-label">Partner Code</label>
            <input
              className="form-control"
              type="text"
              value={partnerCode}
              onChange={(e) => {
                setPartnerCode(e.target.value);
              }}
              placeholder="Partner Code"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={handleModelClose}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handlePartnerCreation}
          >
            Save
          </button>
        </div>
      </Modal>
    </>
  );
};

export default CreatePartner;
