import { axiosInstance } from "./base";
import { headers } from "./base";

export class OemService {

    CMS_BASE_URL= process.env.REACT_APP_CMS_BASE_URL;


    createManufacture = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/oem`, payload, {headers: headers()});
    }

    getManufacture = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/oem?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }

    getVehicle = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/vehicle?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, {headers: headers()});
    }


    createVehicle = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/vehicle`, payload, {headers: headers()});
    }

    getManufactureById = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/oem/${id}`, {headers: headers()});
    }

    getVehicleById = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/vehicle/${id}`, {headers: headers()});
    }


    createChargingStation = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/charging-station`, payload, { headers: headers() });
    }

    getChargingStation = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/charging-station?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, { headers: headers() });
    }

    getChargingStationById = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/charging-station/${id}`, { headers: headers() });
    }


    createBattery = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/battery`, payload, { headers: headers() });
    }

    getBattery = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/battery?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, { headers: headers() });
    }

    getBatteryById = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/battery/${id}`, { headers: headers() });
    }


    createCharger = (payload) => {
        return axiosInstance.post(`${this.CMS_BASE_URL}/charger`, payload, { headers: headers() });
    }

    getCharger = (page=1, perPage=20, search='', fromDate='', toDate='') => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/charger?page=${page}&perPage=${perPage}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`, { headers: headers() });
    }

    getChargerById = (id) => {
        return axiosInstance.get(`${this.CMS_BASE_URL}/charger/${id}`, { headers: headers() });
    }



}
