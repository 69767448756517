// yourSaga.js
import { call, put, takeEvery, all, fork } from 'redux-saga/effects';
import { Toaster } from '../../components/toaster/Toaster';
import { 
    getHotLeadList, 
    getHotLeadListError, 
    getHotLeadListSuccess, 
    getTaskList, 
    getTaskListFailure, 
    getTaskListSuccess 
} from './userSlice';
import { ApiService } from '../../service/ApiService';
import { generateToken } from '../../utils/encryption';

const server = new ApiService();
const toaster = new Toaster();
const cosUrl = process.env.REACT_APP_COS_URL;

// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchTaskList() {
    yield takeEvery(getTaskList.type, fetchTaskList);
}

function* watchHotLeadList() {
    yield takeEvery(getHotLeadList.type, fetchHotLeadList);
}


const getTaskFromServer = async (payload) => (
    await server.getTaskList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
);

const getHotLeadListFromServer = async (payload) => (
    await server.getHotLeads(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error)) 
)


// Worker saga: will be fired on getLenderList actions
function* fetchTaskList(action) {
    try {
        const response = yield call(getTaskFromServer, action?.payload);
        if(response?.data?.success){
            const { data, totalCount } = response?.data;
            data?.forEach((data, index) => {
                data.index = index + 1;
            });
            yield put(getTaskListSuccess({data, count: totalCount}));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(getTaskListFailure(error.message));
        toaster.show(true, error?.message);
    }
}

function* fetchHotLeadList(action) {
    try {
        const userDetails = action?.payload?.userDetails;
        const response = yield call(getHotLeadListFromServer, action?.payload);
        if(response?.data?.success){
            const { data, totalCount } = response?.data?.data;
            data.forEach((data, index) => {
                data.index = index + 1;
                const {emailToken, mobileToken} = generateToken(data?.mobile, userDetails?.email);
                data.assistedUrl = `${cosUrl}/login?e=${emailToken}&m=${mobileToken}`;
            });
            yield put(getHotLeadListSuccess({data, count: totalCount}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getHotLeadListError(error?.message));
        toaster.show(true, error?.message);
    }
}



export default function* adminSaga(){
    yield all([
        fork(watchTaskList),
        fork(watchHotLeadList)
    ])
};
