import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Teams from '../../Pages/Admin/Teams/Teams';
import Details from '../../Pages/Admin/Teams/Details';

const Team = () => {
  return (
    <Routes>
        <Route path='/list' element={<Teams />} />
        <Route path='/details/:teamId' element={<Details />} />
    </Routes>
  )
}

export default Team;
