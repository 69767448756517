import React from "react";
import { Link } from "react-router-dom";


const columns = (permission) => {
  const data = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row?.index,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Loan Id</span>,
      selector: (row) => row?.loanUid, 
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Installment</span>,
      selector: (row) => row?.installments,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Interest Due</span>,
      selector: (row) => row?.interestDue,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Interest Repaid</span>,
      selector: (row) => row?.interestRePaid,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">OutStanding Principle</span>,
      selector: (row) =>row?.outStandingPrinciple,
      sortable: false,
    },
    {
        name: <span className="font-weight-bold fs-13">Payment Mode</span>,
        selector: (row) =>row?.paymentMode,
        sortable: false,
    },
    {
        name: <span className="font-weight-bold fs-13">Penalty Due</span>,
        selector: (row) =>row?.penaltyDue,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Penalty Repaid</span>,
        selector: (row) =>row?.penaltyRePaid,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Pending Amount</span>,
        selector: (row) =>row?.pendingAmount,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Priciple Due</span>,
        selector: (row) =>row?.pricipleDue,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Priciple Repaid</span>,
        selector: (row) =>row?.pricipleRePaid,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Status</span>,
        selector: (row) =>row?.status,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Total Due</span>,
        selector: (row) =>row?.totalDue,
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Created At</span>,
        selector: (row) =>new Date(row?.createdAt).toLocaleDateString(),
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Updated At</span>,
        selector: (row) =>new Date(row?.updatedAt).toLocaleDateString(),
        sortable: false,
     },
     {
        name: <span className="font-weight-bold fs-13">Due Date</span>,
        selector: (row) => new Date(row?.dueDate).toLocaleDateString(),
        sortable: false,
     },
     
  ];

  return data;
}

export default columns;