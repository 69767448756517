import React from 'react'
import Flow from './Flow/Flow'
import './onboarding-system.scss';
import Fields from './Fields';

const Create = () => {


  return (
    <div className='w-100 h-100 d-flex page-content'>
      <div className='w-75'><Flow /></div>
      {/* <div className='w-25'><Fields /></div> */}
    </div>
  )
}

export default Create
