import React, { useEffect, useState } from 'react';
import useClass from '../../hooks/useClass';
import {  
  Card, 
  CardHeader, 
  CardBody, 
} from 'reactstrap';
import emiSceduleColumns from './EmiScheduleTableColumns';
import Table from '../../components/Common/Table';


const EmiSchedule = ({customerCode}) => {
    const [emiScheduleData, setEmiScheduleData] =useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, perPage: 20 });
    const { toaster, server, permission, dispatch } = useClass();

    const columns = emiSceduleColumns(permission);
    const getCustomerEmiSchedule = async () => {
      try {
        const response = await server.getLoanEmiSchedule(customerCode);
        setLoading(false);
        if(response?.data?.success){
          response?.data?.data?.forEach((data, index) => {
            data.index = index + 1;
          });
          setEmiScheduleData(response?.data?.data);
        } else {
          throw new Error(response?.data?.message);
        }
      } catch(error){
          toaster.show(error?.message);
          setLoading(false);
        }
    };

      useEffect(()=> {
       getCustomerEmiSchedule()
      },[]);

    return (
        <>
          <Card className='common-card-style'>
            <CardHeader>
              <div className='section-heading'>EMI Schedule</div>             
            </CardHeader>
            <CardBody>
            <Table
                    data={emiScheduleData}
                    loading={loading}
                    pagination={pagination}
                    setPagination={setPagination}
                    columns={columns}
                    paginationTotalRows={emiScheduleData.length}
                  />
              </CardBody>
            </Card>
        </>
    )
}

export default EmiSchedule;
