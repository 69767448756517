import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Row, Container, Card, CardBody, CardHeader, Col, Button } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { useParams } from 'react-router-dom';
import HtmlModal from '../../../components/Document-Viewer/HtmlModal';
import JoditEditor from 'jodit-react';
import { agreementDynamicFields } from '../../../constants/constants';
import { useSelector } from 'react-redux';
import { approveAgreement, getAgreementDetails, updateAgreementDetails } from '../../../store/admin/adminData';


const Details = () => {

  const { permission, toaster, dispatch } = useClass();
  const { agreementCode } = useParams();
  const headerEditor = useRef(null);
  const bodyEditor = useRef(null);
  const footerEditor = useRef(null);
  const { details, loading } = useSelector((state) => (state?.agreement));
  const [bodyContent, setBodyContent] = useState('');
  const [headerContent, setHeaderContent] = useState('');
  const [footerContent, setFooterContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [allowApprove, setAllowApprove] = useState(true);

  useEffect(() => {
    dispatch(getAgreementDetails(agreementCode));
  }, []);

  useEffect(() => {
    decodeHtml();
  }, [details]);

  const decodeHtml = () => {
    const bodyHtml = atob(details?.body || '');
    const headerHtml = atob(details?.header || '');
    const footerHtml = atob(details?.footer || '');
    setBodyContent(`${decodeURIComponent(bodyHtml)}`);
    setHeaderContent(`${decodeURIComponent(headerHtml)}`);
    setFooterContent(`${decodeURIComponent(footerHtml)}`);
  }


  const updateAgreement = async () => {
    try {
      const result = await toaster.confirmation('Update');
      if(result?.isConfirmed){
        const payload = {
          payload :{
            body: btoa(encodeURIComponent(bodyContent)), 
            header: btoa(encodeURIComponent(headerContent)), 
            footer: btoa(encodeURIComponent(footerContent)),
            type: details?.type
          }, 
          code: agreementCode
        };
        dispatch(updateAgreementDetails(payload));
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch(error){
      toaster.show(true, error?.message);
    }
  }

  const handleApprove = async () => {
    try {
      const result = await toaster.confirmation('Approve');
      if(result?.isConfirmed){
        setIsLoading(true);
        dispatch(approveAgreement(agreementCode));
      }
    } catch(error){
      toaster.show(true, error?.message);
    }
  }

  const handleDoubleClick = (type, data) => {
    const fieldHtml = `%${data?.code}%`;
    const parser = new DOMParser();
    let content = ''; 
    if(type === 'header'){
      content = headerContent;
    } else if(type === 'footer'){
      content = footerContent;
    } else {
      content = bodyContent;
    }
    const doc = parser.parseFromString(content, 'text/html');
    const lastElement = doc.body.lastElementChild;
    
    if (lastElement) {
      lastElement.innerHTML += fieldHtml;
    } else {
      doc.body.innerHTML += fieldHtml;
    }
    
    const updatedContent = doc.body.innerHTML;
    if(type === 'header'){
      setHeaderContent(updatedContent);
    } else if(type === 'footer'){
      setFooterContent(updatedContent);
    } else {
      setBodyContent(updatedContent);
    }
  }

  const editorConfig = useMemo(() => ({
    placeholder: ''
  }), []);

  const handleEditorDirty = (header=headerContent, body=bodyContent, footer=footerContent) => {
    const bodyHtml = decodeURIComponent(atob(details?.body || ''));
    const headerHtml = decodeURIComponent(atob(details?.header || ''));
    const footerHtml = decodeURIComponent(atob(details?.footer || ''));
    return (bodyHtml === body) && (headerHtml === header) && (footerHtml === footer);
  }

  const handleEditorInput = (key, content) => {
    let approveButtonStatus = true;
    if(key === 'header'){
      setHeaderContent(content);
      approveButtonStatus = handleEditorDirty(content, bodyContent, footerContent);
    } else if(key === 'body'){
      setBodyContent(content);
      approveButtonStatus = handleEditorDirty(headerContent, content, footerContent);
    } else if(key === 'footer'){
      setFooterContent(content);
      approveButtonStatus = handleEditorDirty(headerContent, bodyContent, content);
    }
    setAllowApprove(approveButtonStatus);
  }


  return (
    <>
        <Row className="page-content">
        <Container fluid={true}>
            <Breadcrumbs title="agreement" breadcrumbItem="Details" />
            <Row>
              <Col xl={12}>
                <Row>
                  <Col xl={10}>
                  <Card className='common-card-style'>
                    <CardHeader>
                      <div className='d-flex gap-4 justify-content-between'>
                        <div className='section-heading'>{details?.type} - Header</div>
                      </div>
                    </CardHeader>
                    <CardBody style={{height:"35vh"}}>
                      <Row>
                        <div style={{minHeight: '30vh', maxHeight: '65vh', overflowY: 'scroll', padding: '10px'}}>
                          <JoditEditor
		                      	ref={headerEditor}
		                      	value={headerContent}
                            config = {editorConfig}
		                      	onChange={newContent => handleEditorInput('header', newContent)}
		                      />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Col>
                  <Col xl={2}>
                    <Card>
                      <CardHeader>
                        <div className='d-flex gap-4 justify-content-between'>
                          <div className='section-heading'>Dynamic Fields</div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className='d-flex flex-wrap justify-content-between'>
                          {agreementDynamicFields?.map((field, index) => (
                            <div 
                              key={index} 
                              className='mb-2' 
                              onDoubleClick={() => handleDoubleClick('header', field)}
                            >
                              <Button outline color='primary' id={`index${index}`}>
                                {field?.name}
                              </Button>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={10}>
                  <Card className='common-card-style'>
                    <CardHeader>
                      <div className='d-flex gap-4 justify-content-between'>
                        <div className='section-heading'>{details?.type} - Body</div>
                      </div>
                    </CardHeader>
                    <CardBody style={{height:"70vh"}}>
                      <Row>
                        <div style={{minHeight: '60vh', maxHeight: '65vh', overflowY: 'scroll', padding: '10px'}}>
                          <JoditEditor
                            height={'68vh'}
		                      	ref={bodyEditor}
		                      	value={bodyContent}
                            config = {editorConfig}
		                      	onChange={newContent => handleEditorInput('body', newContent)}
		                      />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Col>
                  <Col xl={2}>
                    <Card>
                      <CardHeader>
                        <div className='d-flex gap-4 justify-content-between'>
                          <div className='section-heading'>Dynamic Fields</div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className='d-flex flex-wrap justify-content-between'>
                          {agreementDynamicFields?.map((field, index) => (
                            <div 
                              key={index} 
                              className='mb-2' 
                              onDoubleClick={() => handleDoubleClick('body', field)}
                            >
                              <Button outline color='primary' id={`index${index}`}>
                                {field?.name}
                              </Button>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col xl={10}>
                  <Card className='common-card-style'>
                    <CardHeader>
                      <div className='d-flex gap-4 justify-content-between'>
                        <div className='section-heading'>{details?.type} - Footer</div>
                      </div>
                    </CardHeader>
                    <CardBody style={{height:"35vh"}}>
                      <Row>
                        <div style={{minHeight: '30vh', maxHeight: '65vh', overflowY: 'scroll', padding: '10px'}}>
                          <JoditEditor
                            height={'68vh'}
		                      	ref={footerEditor}
		                      	value={footerContent}
                            config = {editorConfig}
		                      	onChange={newContent => handleEditorInput('footer', newContent)}
		                      />
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Col>
                  <Col xl={2}>
                    <Card>
                      <CardHeader>
                        <div className='d-flex gap-4 justify-content-between'>
                          <div className='section-heading'>Dynamic Fields</div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className='d-flex flex-wrap justify-content-between'>
                          {agreementDynamicFields?.map((field, index) => (
                            <div 
                              key={index} 
                              className='mb-2'
                              onDoubleClick={() => handleDoubleClick('footer', field)}
                            >
                              <Button outline color='primary' id={`index${index}`}>
                                {field?.name}
                              </Button>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className='mt-4 mb-4'>
              <Col className='d-flex justify-content-center gap-4'>
                {permission?.AGREEMENT?.DETAILS?.UPDATE_AGREEMENT_DETAILS && !details?.isApproved && 
                  <LoadingButton
                    color={'success'}
                    onClick={updateAgreement}
                    buttonContent={'Update'}
                    isLoading={loading && !isLoading}
                  />
                }
                {permission?.AGREEMENT?.DETAILS?.APPROVE_AGREEMENT && !details?.isApproved && 
                  <LoadingButton
                    color={'success'} 
                    onClick={handleApprove} 
                    buttonContent={'Approve'} 
                    isLoading={isLoading}
                    disabled={!allowApprove}
                  />
                }
              </Col>
            </Row>
        </Container>
      </Row>
      {/* <HtmlModal
        isOpen={openPreview}
        setIsOpen={setOpenPreview}
        label={'Doc'}
        content={content}
      /> */}
    </>
  )
}

export default Details;
