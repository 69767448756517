import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Agreements from '../../Pages/Admin/Agreements/Agreements';
import Details from '../../Pages/Admin/Agreements/Details';

const Agreement = () => {
  return (
    <Routes>
        <Route path='/list' element={<Agreements />} />
        <Route path='/details/:agreementCode' element={<Details />} />
    </Routes>
  )
}

export default Agreement;
