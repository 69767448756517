import React, { useEffect, useState } from "react";
import UsePanel from "./UserPanel";
import SocialSource from "./SocialSource";
import OverView from "./OverView";
import { Row, Container } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import useClass from "../../hooks/useClass";
import GeoFinance from "./GeoFinance";
import GeoLeads from "./GeoLeads";
import { getDashboardData, getDashboardMonthData } from "../../store/dashboard/dashboard";
import { useSelector } from "react-redux";

const Dashboard = () => {
  document.title = "Dashboard | TapFin";

  const { server, toaster, dispatch } = useClass();
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    getDashboardDetails();
  }, []);
  
  const {dashboardDetails,dashboardMonthData} = useSelector((store) => store?.dashboardData)
  const getDashboardDetails = async () => {
    try {
      dispatch(getDashboardData());
      dispatch(getDashboardMonthData());
    } catch(error){
      toaster.show(true, error?.message);
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="TapFin" breadcrumbItem="Dashboard" />
          <UsePanel dashboardData={dashboardDetails} />
          <Row>
            <OverView dashboardMonthData={dashboardMonthData} />
            <GeoFinance />
            <SocialSource />
            <GeoLeads />
          </Row>
          <Row>
            {/* <OrderStatus /> */}
            {/* <Notifications /> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
