import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { 
  Card, 
  CardBody, 
  CardHeader, 
  Container, 
  Row, 
  Col,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { generatePermissionsKeyPair } from '../../../utils/helper';

const Details = () => {

    const { toaster, adminServer, permission } = useClass();
    const { roleId } = useParams();
    const [permissionForm, setPermissionForm] = useState({});
    const [roleInfoForm, setRoleInfoForm] = useState({});
    const [roleInfo, setRoleInfo] = useState({});
    const [permissionMasterData, setPermissionMasterData] = useState([]);
    const [currentRolePermissions, setCurrentRolePermissions] = useState({});

    useEffect(() => {
      fetchPermissionMasterData();
      fetchCurrentRoleData();
    }, [])

    const fetchPermissionMasterData = async () => {
      try {
        const response = await adminServer.fetchPermissionMasterData();
        if(response?.data?.success){
          setPermissionMasterData(response?.data?.permissions);
        } else {
          throw new Error(response?.data?.message);
        }
      } catch(error){
        toaster.show(true, error.message);
      }
    }

    const fetchCurrentRoleData = async () => {
      try {
        const response = await adminServer.fetchCurrentRoleData(roleId);
        if(response?.data?.success){
          setRoleInfo(response?.data?.roleInfo);
          const result = generatePermissionsKeyPair(response?.data?.permissions);
          setCurrentRolePermissions(result);
        } else {
          throw new Error(response?.data?.message);
        }
      } catch(error){
        toaster.show(error?.message);
      }
    }

    const handlePermissionChange = (permissionCode, event, module, subModule) => {
      if(permission?.ROLES?.DETAILS?.UPDATE_ROLE_DETAILS){
        if(permissionForm[permissionCode] !== undefined){
          delete permissionForm[permissionCode];
        } else {
          setPermissionForm((prev) => ({
            ...prev,
            [permissionCode]: event?.target?.checked
          }))
        }
  
        setCurrentRolePermissions((prev) => ({
          ...prev,
          [module?.moduleCode]: {
            ...prev[module?.moduleCode],
            [subModule?.subModuleCode]: {
              ...prev[module?.moduleCode]?.[subModule.subModuleCode],
              [permissionCode]: event?.target?.checked
            }
          }
        }))
      } else {
        toaster.show(true, 'You dont have permission to edit.');
      }
    }

  const handleRoleInfoChange = (key, value) => {
    if(permission?.ROLES?.DETAILS?.UPDATE_ROLE_DETAILS){
      if(roleInfoForm[key] !== undefined){
        delete roleInfoForm[key];
      } else {
        setRoleInfoForm((prev) => ({
          ...prev,
          [key]: value
        }))
      }

      setRoleInfo((prev) => ({
        ...prev,
        [key]: value
      }))
    } else {
      toaster.show(true, 'You dont have permission to edit.');
    }
  }

  const handleUpdate = async (roleId) => {
    try {
      const result = await toaster.confirmation('Update');
      if(result?.isConfirmed){
        if(Object.keys(permissionForm).length > 0 ){
          handlePermissionUpdate();
        }
        if(Object.keys(roleInfoForm).length > 0){
          handleRoleInfoUpdate();
        }
      }
    } catch(error) {
      toaster.show(true, error?.message);
    }
  }

  const handlePermissionUpdate = async () => {
    try {
      const payload = {
        id: roleId,
        permissionList: permissionForm
      }
      const response = await adminServer.updateRolePermissions(payload);
      if(response?.data?.success){
        toaster.show(false, 'Permissions updated successfully');
        window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch(error) {
      toaster.show(true, error?.message);
    }
  }

  const handleRoleInfoUpdate = async () => {
    try {
      const payload = {
        ...roleInfoForm,
        id: roleId,
      }
      const response = await adminServer.updateRoleDetails(payload);
      if(response?.data?.success){
        toaster.show(false, 'Role Info updated successfully');
        window.location.reload();
      } else {
        throw new Error(response?.data?.message);
      }
    } catch(error) {
      toaster.show(true, error?.message);
    }
  }

  return (
    <>
      <Row className="page-content">
            <Container fluid={true}>
              <Breadcrumbs title="roles" breadcrumbItem="Details" />
              <Row>
                <Col xl={12}>
                  <Card className='common-card-style'>
                    <CardHeader>
                      <div className='section-heading d-flex gap-4 justify-content-between'>
                        <div>Role Details</div>
                        <div className='form-check form-switch form-switch-md'>
                          <input 
                            type="checkbox" 
                            className="form-check-input me-2" 
                            checked={roleInfo?.isActive || false}
                            onChange={(e) => handleRoleInfoChange('isActive', e.target.checked)}
                          />
                          <label className="form-check-label" style={roleInfo?.isActive ? {color: "green"} : {color: "red"}}>
                            {roleInfo?.isActive ? "Active" : "In-Active"}
                          </label>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Role Name</Row>
                          <Row className='data-value me-4'>{roleInfo?.role || '-'}</Row>
                        </div>
                        <div className='d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12'>
                          <Row className='data-key ms-4'>Description</Row>
                          <Row className='data-value me-4'>{roleInfo?.description || '-'}</Row>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardHeader>
                      <div className='section-heading'>Permissions</div>
                    </CardHeader>
                    <CardBody>
                      {permissionMasterData?.map((module, index) => (
                        <Row key={index}>
                          <Col xl={12}>
                            <Card>
                              <CardHeader>
                                <div className='section-heading'>{module?.moduleCode}</div>
                              </CardHeader>
                              <CardBody>
                                {module?.subModules?.map((subModule, index2) => (
                                  <div className='sub-module-border mb-4' key={index2}>
                                    <span className='sub-module-heading ps-2 pe-2 data-value'>{subModule?.subModuleCode}</span>
                                    <Row className='d-flex justify-content-start p-2'>
                                      {subModule?.permissions.map((key, index3) => (
                                        <div className='col-lg-4 col-md-6 col-sm-12 mb-2' key={index3}>
                                          <div className='form-check form-switch form-switch-md ms-4'>
                                            <input 
                                              type="checkbox" 
                                              className="form-check-input"
                                              checked={currentRolePermissions?.[module.moduleCode]?.[subModule?.subModuleCode]?.[key?.permissionCode] || false}
                                              onChange={(e) => handlePermissionChange(key?.permissionCode, e, module, subModule)} 
                                            />
                                            <span className={`data-key ${permissionForm?.[key?.permissionCode] !== undefined && 'edited-permissions'}`}>
                                              {key?.permissionCode}
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </Row>
                                  </div>
                                ))}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      ))}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
                <Row className='m-4'>
                    <Col className='d-flex justify-content-center gap-4'>
                      {permission?.ROLES?.DETAILS?.UPDATE_ROLE_DETAILS && (Object.keys(permissionForm).length > 0 || Object.keys(roleInfoForm).length > 0) &&
                        <LoadingButton color={'success'} onClick={() => handleUpdate(roleId)} buttonContent={'Update'}/>
                      }
                    </Col>
                </Row>
            </Container>
        </Row>
    </>
  )
}

export default Details;