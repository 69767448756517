import React from "react";
import Routes from "./Routes/index";
import './assets/scss/theme.scss';


function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
