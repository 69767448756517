import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Chargers from '../../Pages/Oem/Charger/Oem';
import Details from '../../Pages/Oem/Charger/Details';

const Charger = () => {
  return (
    <Routes>
        <Route path='/list' element={<Chargers />} />
        <Route path='/details/:chargerId' element={<Details />} />
    </Routes>
  )
}

export default Charger;
