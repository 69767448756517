import React, { useEffect, useState } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import AsyncDropdown from '../../../components/Dropdown/AsyncDropdown';
import { getUserList } from '../../../store/admin/adminData';
import { useSelector } from 'react-redux';

const Create = (props) => {

  const { toaster, adminServer, navigate, permission, dispatch } = useClass();
  const {isOpen, setIsOpen, teams} = props;
  const { list } = useSelector((state) => (state?.user));
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: '',
    owner: '',
    description: ''
  });
  const [parentTeam, setParentTeam] = useState('');

  useEffect(() => {
    if(permission?.USERS?.LIST?.VIEW_USER_LIST_ALL){
      dispatch(getUserList());
    }
  }, [])

  const fetchUsersList = (search='', callback) => {
    dispatch(getUserList({search, callback}));
  }

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    if(key === 'parentTeam'){
      setParentTeam(value);
    } else {
      setForm((prev) => ({
        ...prev,
        [key]: value
      }))
    }
  }

  const handleTeamCreation = async () => {
    try{
      setIsLoading(true);
      let payload = {...form, owner: form?.owner?.value}
      if(validateForm(payload)){
        if(parentTeam){
          payload ={...payload, parentTeamId: parentTeam?.id}
        }
        const response = await adminServer.createTeam(payload);
        setIsLoading(false);
        if(response?.data?.success){
          navigate(`/teams/details/${response?.data?.data?.id}`);
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please Fill Few Details To Create Team');
      }
    } catch(error){
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  }

  const validateForm = (payload) => {
    const result = Object.keys(payload).every((key) => {
      return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create Team
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div>
                <TextInput 
                  label={'Name'}
                  required={true}
                  name={'name'}
                  value={form?.name}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <AsyncDropdown
                  label={'Owner'}
                  name={'owner'}
                  value={form?.owner}
                  handleInput={handleInput}
                  required={true}
                  returnsObj={true}
                  defaultOptions={list}
                  loadOptions={fetchUsersList}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Description'}
                  required={true}
                  name={'description'}
                  value={form?.description}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <Dropdown
                  label={'Parent team'}
                  name={'parentTeam'}
                  value={parentTeam}
                  handleInput={handleInput}
                  options={teams}
                  required={false}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleTeamCreation}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default Create;
