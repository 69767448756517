import React from 'react';
import withRouter from "../components/Common/withRouter";

const NonAuthLayout = (props) => {

  return (
    <>{props.children}</>
  );
};

export default withRouter(NonAuthLayout);
