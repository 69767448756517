import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Alert, Col, Row } from 'reactstrap';
import axios from 'axios';
import Dropdown from '../../../components/Dropdown/Dropdown';
import TextInput from '../../../components/text-input/TextInput';
import useClass from '../../../hooks/useClass';
import { updateCustomerPayment } from '../../../store/application/payment';

const PaymentModal = ({ isOpen, setIsOpen, customerCode }) => {
  const [formData, setFormData] = useState({
    productType: 'LOAN',
    primaryCode: '',
    partnerCode: '',
    customerCode: customerCode || '',
    category: 'REPAYMENT',
    transactionId: '',
    utrNo: '',
    amount: '',
    description: '',
    mode: 'CASH',
    source: 'BILLDESK',
    status: ''
  });

  const [loading, setLoading] = useState(false);
  const { server, toaster,dispatch } = useClass();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handlePaymentCreate = async (e) => {
    e.preventDefault(); 
    dispatch(updateCustomerPayment(formData))
    setIsOpen(false);
  };

  return (
    <Modal
    isOpen={isOpen}
    toggle={() => setIsOpen(!isOpen)}
    centered
    className="custom-modal"
  >
    <ModalHeader toggle={() => setIsOpen(!isOpen)}>Create Payment Entry</ModalHeader>
    <ModalBody>
      <Form onSubmit={handlePaymentCreate}>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Dropdown
                label={'Product Type'}
                value={formData.productType?.value}
                required={true}
                name={'productType'}
                options={[
                  { label: 'LOAN', value: 'loan' },
                  { label: 'INSURANCE', value: 'insurance' },
                  { label: 'LEASE', value: 'lease' }
                ]}
                handleInput={(key, value) => handleChange({ target: { name: key, value:value?.label } })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <TextInput
                name={'primaryCode'}
                label={'Primary Code'}
                value={formData.primaryCode}
                required={true}
                handleInput={(key, value) => handleChange({ target: { name: key, value } })}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <TextInput
            name={'partnerCode'}
            label={'Partner Code (Optional)'}
            value={formData.partnerCode}
            handleInput={(key, value) => handleChange({ target: { name: key, value } })}
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name={'customerCode'}
            label={'Customer Code'}
            value={formData.customerCode}
            required={true}
            handleInput={(key, value) => handleChange({ target: { name: key, value } })}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={'Category'}
            value={formData.category?.value}
            required={true}
            name={'category'}
            options={[
              { label: 'REPAYMENT', value: 'repayment' },
              { label: 'INTEREST', value: 'interest' },
              { label: 'PENALTY', value: 'penalty' },
              { label: 'DISBURSAL', value: 'disbursal' },
              { label: 'PROCESSING_FEES', value: 'processing_fees' },
              { label: 'REFUND', value: 'refund' },
              { label: 'WRITE_OFF', value: 'write_off' },
              { label: 'CANCELLATION', value: 'cancellation' },
              { label: 'DISCOUNT', value: 'discount' },
            ]}
            handleInput={(key, value) => handleChange({ target: { name: key, value:value?.label } })}
          />
        </FormGroup>
        <Row>
          <Col md={6}>
            <FormGroup>
              <TextInput
                name={'transactionId'}
                label={'Transaction ID'}
                value={formData.transactionId}
                required={true}
                handleInput={(key, value) => handleChange({ target: { name: key, value } })}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <TextInput
                name={'utrNo'}
                label={'UTR No'}
                value={formData.utrNo}
                required={true}
                handleInput={(key, value) => handleChange({ target: { name: key, value } })}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <TextInput
            name={'amount'}
            label={'Amount'}
            value={formData.amount}
            required={true}
            handleInput={(key, value) => handleChange({ target: { name: key, value } })}
            type='number'
            step="0.01"
          />
        </FormGroup>
        <FormGroup>
          <TextInput
            name={'description'}
            label={'Description (Optional)'}
            value={formData.description}
            handleInput={(key, value) => handleChange({ target: { name: key, value } })}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={'Mode'}
            value={formData.mode?.value}
            required={true}
            name={'mode'}
            options={[
              { label: 'CASH', value: 'cash' },
              { label: 'CHEQUE', value: 'credit_card' },
              { label: 'UPI', value: 'upi' },
              { label: 'NEFT', value: 'neft' },
              { label: 'RTGS', value: 'rtgs' },
              { label: 'IMPS', value: 'imps' }
            ]}
            handleInput={(key, value) => handleChange({ target: { name: key, value:value?.label } })}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={'Source'}
            value={formData.source?.value}
            required={true}
            name={'source'}
            options={[
              { label: 'BILLDESK', value: 'billdesk' },
              { label: 'BRANCH', value: 'branch' },
              { label: 'BANK', value: 'bank' },
              { label: 'CRED', value: 'cred' },
              { label: 'PAYTM', value: 'paytm'},
              { label: 'PORTAL', value: 'portal'}
            ]}
            handleInput={(key, value) => handleChange({ target: { name: key, value:value?.label } })}
          />
        </FormGroup>
        <FormGroup>
          <Dropdown
            label={'Status (Optional)'}
            value={formData.status?.value}
            name={'status'}
            options={[
              { label: 'Completed', value: 'completed' },
              { label: 'Pending', value: 'pending' },
              { label: 'Failed', value: 'failed' }
            ]}
            handleInput={(key, value) => handleChange({ target: { name: key, value:value?.label } })}
          />
        </FormGroup>
      </Form>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={handlePaymentCreate}>Submit</Button>
      <Button color="secondary" onClick={() => setIsOpen(false)}>Cancel</Button>
    </ModalFooter>
  </Modal>
  );
};

export default PaymentModal;
