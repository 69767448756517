import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ApiLogs from '../../Pages/Admin/ApiLogs/ApiLogs';
import Details from '../../Pages/Admin/ApiLogs/Details';

const ApiLog = () => {
  return (
    <Routes>
        <Route path='/list' element={<ApiLogs />} />
        <Route path='/details/:logId' element={<Details />} />
    </Routes>
  )
}

export default ApiLog;
