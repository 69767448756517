import React from 'react';
import { Card, CardBody, Col, Row } from "reactstrap";
import Apaexlinecolumn from '../../components/Charts/apaexlinecolumn';

const GeoFinance = () => {

    const OverViewData = [
        {
            title: "Total revenue",
            count: 2,
            percentage: '10%',
        },
        {
            title: "Expense",
            count: 2,
            percentage: '10%',
            color: 'red'
        },
        {
            title: "Net profit",
            count: 2,
            percentage: '10%',
            color: 'green'
        }
    ]
    
    return (
        <>
            <Col xl={4} md={6}>
                <Card>
                    <CardBody>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                <h5 className="card-title">Revenue vs. Profit by Region(Dummy)</h5>
                            </div>
                            <div className="flex-shrink-0">
                                <select className="form-select form-select-sm mb-0 my-n1">
                                  {[
                                    "May",
                                    "April",
                                    "March",
                                    "February",
                                    "January",
                                    "December",
                                  ].map((item, key) => (
                                    <option key={key} value={item}>
                                      {item}
                                    </option>
                                  ))}
                                </select>
                            </div>
                        </div>
                        <div>
                            <Apaexlinecolumn categories={['Delhi', 'Bengaluru', 'Hyderabad', 'Chennai']} />
                        </div>
                    </CardBody>
                    <CardBody className="border-top">
                        <div className="text-muted text-center">
                            <Row>
                                {OverViewData.map((item, key) => (<Col md={4} key={key} className="border-end">
                                    <div>
                                        <p className="mb-2"><i className={"mdi mdi-circle font-size-12 me-1 text-" + item.color}></i> {item.title}</p>
                                        <h5 className="font-size-16 mb-0">
                                            ₹ {item.count} 
                                            {/* <span className="text-success font-size-12">
                                                <i className="mdi mdi-menu-up font-size-14 me-1"></i>
                                                {item.percentage} %
                                            </span> */}
                                        </h5>
                                    </div>
                                </Col>))}
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </>
    );
};

export default GeoFinance;