import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProductSchemes from '../../Pages/Admin/ProductScheme/ProductSchemes';
import Details from '../../Pages/Admin/ProductScheme/Details';


const ProductScheme = () => {
  return (
    <Routes>
        <Route path='/list' element={<ProductSchemes />} />
        <Route path='/details/:code' element={<Details />} />
    </Routes>
)
}

export default ProductScheme;
