import React, { useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../../components/Common/Table';
import mouColumns from './Columns';
import useClass from '../../../hooks/useClass';
import Create from './Create';
import Filters from '../../../components/Common/Filters';
import { getAgreementList } from '../../../store/admin/adminData';
import { useSelector } from 'react-redux';

const Agreements = () => {
    document.title = "Agreements | TapFin";

    const { permission, dispatch } = useClass();
    const { list, totalList, loading} = useSelector((state) => (state?.agreement));
    const [createNewAgreement, setCreateNewAgreement] = useState(false);
    const [pagination, setPagination] = useState({page: 1, perPage: 20});
    const [selectedTemplated, setSelectedTemplate] = useState('');
    const columns = mouColumns(permission, handleSelection);

    const fetchAgreementList = (params) => {
        const { pagination, searchText, dateRange } = params;
        dispatch(getAgreementList({page: pagination?.page, perPage: pagination?.perPage, search: searchText, dateRange: dateRange}))
    }

    function handleSelection (code) {
        setSelectedTemplate(code);
        setCreateNewAgreement(true);
    }

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="MOU" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-between'>
                                    <h5 className="card-title mb-0">MOU</h5>
                                    <Filters
                                        fetchDataFromServer={fetchAgreementList}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        showCreateButton={permission?.AGREEMENT?.LIST?.CREATE_NEW_AGREEMENT}
                                        onCreateButtonClick={() => setCreateNewAgreement(true)}
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table 
                                        data={list} 
                                        loading={loading}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columns={columns}
                                        paginationTotalRows={totalList}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Create 
                isOpen={createNewAgreement} 
                setIsOpen={setCreateNewAgreement} 
                selectedTemplated={selectedTemplated}
            />
        </>
    );
};

export default Agreements;