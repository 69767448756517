import React, { useState } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
  

const CreateRole = (props) => {

  const { toaster, adminServer, navigate, permission } = useClass();
  const {isOpen, setIsOpen} = props;
  const [roleName, setRoleName] = useState('');
  const [description, setDescription] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleRoleCreation = async () => {
    try{
      setIsLoading(true);
      if(validateForm()){
        const response = await adminServer.createNewRole({roleName, description});
        setIsLoading(false);
        if(response?.data?.success){
          if(permission?.ROLES?.DETAILS?.VIEW_ROLE_DETAILS){
            navigate(`/roles/details/${response?.data?.roleId}`);
          }
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please Fill Few Details To Create New Role');
      }
    } catch(error){
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  }

  const validateForm = () => {
    return roleName.trim() !== "" && description.trim() !== "";
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create Role
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div>
                <TextInput 
                  label={'Role Name'}
                  required={true}
                  name={'roleName'}
                  value={roleName}
                  handleInput={(key, value) => {setRoleName(value)}}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Role Description'}
                  required={true}
                  name={'description'}
                  value={description}
                  handleInput={(key, value) => {setDescription(value)}}
                  type={'text'}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleRoleCreation}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default CreateRole;
