import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Roles from '../../Pages/Admin/Roles/Roles';
import Details from '../../Pages/Admin/Roles/Details';

const Role = () => {
  return (
    <Routes>
        <Route path='/list' element={<Roles />} />
        <Route path='/details/:roleId' element={<Details />} />
    </Routes>
  )
}

export default Role;
