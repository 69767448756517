import { memo, useState } from 'react';
import { Handle, Position, NodeResizer } from 'reactflow';
import '../onboarding-system.scss';
import Model from '../Model';

const Node = ({ data, selected }) => {

  const [isOpen, setIsOpen] = useState(false);
  const [stepData, setStepData] = useState();
  const [stepFields, setStepFields] = useState([]);

  const handleNodeClick = () => {
    setIsOpen(true);
  }

  const handleNodeData = (form) => {
    setStepData(form);
  }

  const handleDrop = (event) => {
    event.preventDefault();
    const data = JSON.parse(event.dataTransfer.getData('field'));
    setStepFields((prev) => {
      if (!prev.some(field => field.code === data.code)) {
        return [...prev, data];
      }
      return prev;
    });
  }

  const handleDragOver = (event) => {
    event.preventDefault();
  }

  const handleFieldRemove = (index) => {
    return () => {
      setStepFields((prev) => prev.filter((_, i) => i !== index));
    }
  }

  return (
    <>
      <NodeResizer color="green" isVisible={selected} minWidth={200} minHeight={30} />
      <Handle type="target" position={Position.Top} />
      <div className='node-style' onDoubleClick={handleNodeClick} onDrop={handleDrop} onDragOver={handleDragOver}>
        <div className='node-content'>
          {data.label}
          {stepData && 
            <div>
              <div>{stepData?.stepName} - {stepData?.stepSlug}</div>
            </div>
          }
          {stepFields.map((field, index) => (
            <div className='picked-field m-2' key={index}>
              {field.fieldName}
              <button style={{borderRadius: '50%'}}>
                <span onClick={handleFieldRemove(index)}>
                  &times;
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} />
      <Model isOpen={isOpen} setIsOpen={setIsOpen} handleSubmit={handleNodeData} />
    </>
  );
};

export default memo(Node);
 