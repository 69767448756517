import React, { useState } from 'react';
import { Col, Container, Row, Table, Input, Button, Card, CardBody, CardTitle } from 'reactstrap';
import useClass from '../../../hooks/useClass';
import { getInvoiceDetailsAction } from '../../../store/loan/loanAction';
import { useSelector } from 'react-redux';

const formatDate = () => {
  const date = new Date();
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date);
};

const InvoiceTable = ({loanUid}) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [gstNumber, setGstNumber] = useState('');
  const { toaster, dispatch } = useClass();

  const {invoiceDetails} = useSelector((store) => store?.loanAction)

  const handleSubmit = () => {
    if (gstNumber) {
      setIsSubmitted(true);
      dispatch(getInvoiceDetailsAction({loanUid,gstNumber}))
    } else {
        toaster.show(true,"Please enter the GST number.");
    }
  };

  return (
    <Container>
      {/* GST Number Input and Submit Button */}
      {!isSubmitted && (
        <Row className='my-4 justify-content-center'>
          <Col md="4" className="text-center">
            <Input
              type="text"
              placeholder="Enter GST Number"
              value={gstNumber}
              onChange={(e) => setGstNumber(e.target.value)}
            />
          </Col>
          <Col md="4" >
            <Button color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Col>
        </Row>
      )}

      {/* Invoice Header */}
      {isSubmitted && (
        <>
          <Row className='my-4'>
            <Col>
              <Table bordered>
                <thead>
                  <tr>
                    <th colSpan="2" className='text-center'>
                      <h5>Invoice No: {invoiceDetails?.invoiceNumber}</h5>
                      <p>Invoice Date: {invoiceDetails?.invoiceDate}</p>
                    </th>
                  </tr>
                  <tr>
                    <th>Details of Service Provider (Tapsys)</th>
                    <th>Details of Service Receiver ({invoiceDetails?.name})</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Name: Tapsys Private Limited</td>
                    <td>Name: {invoiceDetails?.name}</td>
                  </tr>
                  <tr>
                    <td>Address: I-202, The Trees, Pirojshanagar, Vikhroli East, Mumbai - 400079</td>
                    <td>Address: {invoiceDetails?.address}</td>
                  </tr>
                  <tr>
                    <td>State: Maharashtra</td>
                    <td>State: {invoiceDetails?.state}</td>
                  </tr>
                  <tr>
                    <td>GSTIN: 27AAKCT1534H1ZA</td>
                    <td>GSTIN: {invoiceDetails?.gstNumber}</td>
                  </tr>
                  <tr>
                    <td>PAN NO: AAKCT1534H</td>
                    <td>PAN NO: {invoiceDetails?.panNumber}</td>
                  </tr>
                  <tr>
                    <td>HSN /SAC CODE: 997159</td>
                    <td>Partner Code: {invoiceDetails?.lenderCode}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>

          {/* Subject Box */}
          <Row className='my-4'>
            <Col>
              <Card>
                <CardBody className='d-flex gap-1'>
                  <CardTitle tag="h5">Subject:</CardTitle>
                  <p>Commission for the month of: {invoiceDetails?.sanctionDate}</p>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Invoice Details Table */}
          <Row className='my-4'>
            <Col style={{ overflowX: 'auto' }}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Sr No</th>
                    <th>Customer Name</th>
                    <th>Loan ID</th>
                    <th>Product</th>
                    <th>Loan Amount (INR)</th>
                    <th>Arrangement Commission %</th>
                    <th>Amount (INR)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>TapFin Capital</td>
                    <td>{invoiceDetails?.lenderLoanUid}</td>
                    <td>{invoiceDetails?.productName}</td>
                    <td>{invoiceDetails?.approvedAmount}</td>
                    <td>{invoiceDetails?.lenderCommissionPercentage}%</td>
                    <td>{invoiceDetails?.commissionAmount}</td>
                  </tr>
                  <tr>
                    <td>CGST ({invoiceDetails?.CGST}%)</td>
                    <td colSpan="6" className="text-right">{invoiceDetails?.cgstAmount}</td>
                  </tr>
                  <tr>
                    <td>SGST ({invoiceDetails?.SGST}%)</td>
                    <td colSpan="6" className="text-right">{invoiceDetails?.sgstAmount}</td>
                  </tr>
                  <tr>
                    <td>IGST ({invoiceDetails?.IGST}%)</td>
                    <td colSpan="6" className="text-right">{invoiceDetails?.igstAmount}</td>
                  </tr>
                  <tr>
                    <td>Decimal Amount</td>
                    <td colSpan="6" className="text-right">{invoiceDetails?.decimalValue}</td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td colSpan="6" className="text-right font-weight-bold">INR {invoiceDetails?.invoiceAmount}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="7" className="text-center font-weight-bold">
                      Amount in Words: {invoiceDetails?.invoiceAmountInWords}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </Col>
          </Row>

          {/* Bank Details and Signatory */}
          <Row className='my-4'>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">Company Bank Details:</CardTitle>
                  <p>Account Name: M/S. TAPSYS PRIVATE LIMITED</p>
                  <p>Bank Name: IDFC Bank</p>
                  <p>Branch: BKC- NAMAN BRANCH</p>
                  <p>Bank Account No: 10173604524</p>
                  <p>IFSC Code: IDFB0040101</p>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle tag="h5">For TAPSYS PRIVATE LIMITED</CardTitle>
                  <p>AUTHORIZED SIGNATORY</p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </Container>
  );
};

export default InvoiceTable;
