import React, { useState } from 'react';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../../components/Common/Table';
import batteryColumns from './Columns'; // Assuming Columns is now related to batteries
import useClass from '../../../hooks/useClass';
import CreateBatteryModel from './Create'; // Battery creation modal
import Filters from '../../../components/Common/Filters';
import { useSelector } from 'react-redux';
import { getBatteryList } from '../../../store/oem/oemData'; // Battery fetching action

const Batteries = () => {
    document.title = "Batteries | TapFin"; // Proper title for batteries page

    const { permission, dispatch } = useClass();
    const columns = batteryColumns(permission); // Use the same Columns for battery
    const { list, totalList, loading } = useSelector((state) => (state?.battery)); // Use battery state
    const [createNewBattery, setCreateNewBattery] = useState(false); // Battery-specific modal state
    const [pagination, setPagination] = useState({ page: 1, perPage: 20 });

    const fetchBatteryList = (params) => {
        const { pagination, searchText, dateRange } = params;
        dispatch(getBatteryList({
            page: pagination?.page,
            perPage: pagination?.perPage,
            search: searchText,
            dateRange: dateRange
        }));
    }

    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Batteries" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader className='d-flex justify-content-between'>
                                    <h5 className="card-title mb-0">Batteries</h5>
                                    <Filters
                                        fetchDataFromServer={fetchBatteryList} // Fetch battery list
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        showCreateButton={permission?.BATTERY?.LIST?.CREATE_NEW_BATTERY} // Permission for battery creation
                                        onCreateButtonClick={() => { setCreateNewBattery(true) }} // Show battery creation modal
                                    />
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        data={list} // Battery list data
                                        loading={loading}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columns={columns} // Use same columns for battery
                                        paginationTotalRows={totalList}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <CreateBatteryModel
                isOpen={createNewBattery} // Proper modal naming for battery creation
                setIsOpen={setCreateNewBattery}
            />
        </>
    );
};

export default Batteries;
