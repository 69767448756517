import React, { useEffect, useState } from 'react';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import Table from '../../components/Common/Table';
import LoanTableColumns from './LoanTableColumns';


const Loans = () => {
    document.title = "Customers | TapFin";

    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState({page: 1, perPage: 20});
    const columns = LoanTableColumns;

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, [])

    const data = [
        {
            srNo: "01",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP888HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "In-Progress"
        },
        {
            srNo: "02",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAP8898HJJ",
            product: "Insurance",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "Closed"
        },
        {
            srNo: "03",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "New"
        },
        {
            srNo: "04",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "Re-Open"
        },
        {
            srNo: "05",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "On-Hold"
        },
        {
            srNo: "06",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "Open"
        },
        {
            srNo: "07",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "In-Progress"
        },
        {
            srNo: "08",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "In-Progress"
        },
        {
            srNo: "09",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "In-Progress"
        },
        {
            srNo: "10",
            name: 'Suraj Yadav',
            customerCode: "TAPCR978989HY",
            applicationID: "TAPAPP898988HJH",
            product: "Loan",
            mobile: "9989898998",
            createDate: "05 Oct, 2021",
            status: "In-Progress"
        },
    ];


    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumbs title="TapFin" breadcrumbItem="Customers" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Customers List</h5>
                                </CardHeader>
                                <CardBody>
                                    <Table 
                                        data={data} 
                                        loading={loading}
                                        pagination={pagination}
                                        setPagination={setPagination}
                                        columns={columns}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Loans;