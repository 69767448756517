import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row.index,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Product Name</span>,
            selector: row =>(permission?.PRODUCTS?.DETAILS?.VIEW_PRODUCT_DETAILS ? <Link to={`/products/details/${row?.code}`}>{row.name}</Link> : row?.name),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Category Code</span>,
            selector: row =>row?.categoryCode,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Sub Category Code</span>,
            selector: row =>row?.subCategory,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Product Code</span>,
            selector: row => (row.code),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Product Type</span>,
            selector: row => row.type,
            sortable: false
        },
        {
            name: <span className="font-weight-bold fs-13">Created By</span>,
            selector: (row) => row?.createdBy,
            sortable: false,
          },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row =>new Date(row?.createdAt).toLocaleDateString() ,
            sortable: false
        }
    ];

    return data;
}

export default columns;
