import React, { useState } from 'react';
import { Modal } from "reactstrap";
import TextInput from '../../../components/text-input/TextInput';

const FieldModel = (props) => {

    const {isOpen, setIsOpen, handleSubmit} = props;
    const [roleName, setRoleName] = useState('');
    const [description, setDescription] = useState('');
    const [form, setForm] = useState({});
  
    const handleModelClose = () => {
      setIsOpen(false);
    }
  
    const handleInput = (key, value) => {
      setForm((prev) => ({
          ...prev,
          [key]: value
      }))
    }
  
    const handleNodeUpdate = async () => {
      handleSubmit(form);
      setForm({})
      handleModelClose();
    }
  
    const validateForm = () => {
      return roleName.trim() !== "" && description.trim() !== "";
    }

    return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Fill Step Details
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div>
                <TextInput 
                  label={'Field Name'}
                  required={true}
                  name={'fieldName'}
                  value={form?.fieldName}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Field Code'}
                  required={true}
                  name={'fieldCode'}
                  value={form?.fieldCode}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Field Description'}
                  required={true}
                  name={'fieldDescription'}
                  value={form?.fieldDescription}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Field Type'}
                  required={true}
                  name={'fieldType'}
                  value={form?.fieldType}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <button type="button" onClick={handleNodeUpdate} className="btn btn-success" data-dismiss="modal">
                Update
              </button>
            </div>
        </Modal>
    </>
  )
}

export default FieldModel
