import React, { useState } from 'react'
import { useMsal } from "@azure/msal-react";
import { loginRequest } from './authConfig';
import useClass from '../../../hooks/useClass';
import { BrowserAuthError } from '@azure/msal-browser';
import LoadingButton from '../../../components/Common/LoadingButton';



const Oauth = ({tapfinUser}) => {
    const { instance } = useMsal()
    const {server, toaster, navigate} = useClass()
    const [loading, setLoading] = useState(false)
    const handleLogin = async () => {
      try {
        const  response = await instance.loginPopup(loginRequest);
        const  accessToken = response.accessToken;

        // Send accessToken to your backend for verification
         await sendTokenToBackend(accessToken);
      } catch (error) {
        if (error instanceof BrowserAuthError && error.errorCode === 'user_cancelled'){
          return toaster.show(true, 'Retry Authentication');
        }
      }
       
      }

      const sendTokenToBackend = async (accessToken) => {
        try {
          setLoading(true)
        const response  = await server.validateOauthLoginCredentials({accessToken});
        if(response?.data?.success){
            localStorage.setItem('token', response?.data?.data?.token);
            navigate('/');
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
         toaster.show(true, error?.message);
        }
      }
  return (
    <div style={{ width: tapfinUser ? '100%' : '45%' }} className="d-grid mt-4 flex-grow-1">
      <LoadingButton isLoading={loading} onClick={handleLogin} buttonContent={
    <>
      Log In with Microsoft <i className="fas fa-border-all ml-3"></i>
    </>
  } />
    </div>
  )
}

export default Oauth
