import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row?.index || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => (permission?.HOT_LEADS?.DETAILS?.VIEW_HOT_LEAD_DETAILS ? <Link to={`/hot_leads/details/${row?.id}`}>{row?.name || '-'}</Link> : row?.name),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Customer Code</span>,
            selector: row => row?.customerCode || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Application ID</span>,
            selector: row => (row?.application?.applicationID),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Product Name</span>,
            selector: row => row?.application?.productName || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Mobile Number</span>,
            selector: row => row?.mobile || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Assisted Link</span>,
            selector: row => (permission?.HOT_LEADS?.LIST?.CREATE_NEW_HOT_LEAD ? <a href={row?.assistedUrl} target="_blank" rel="noopener noreferrer">{'Journey Link' || '-'}</a> : 'Link'),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createAt).toLocaleDateString() || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row?.application?.status) {
                    case "Re-open":
                        return <span className="badge badge-soft-info"> {row?.application?.status || '-'} </span>;
                    case "On-Hold":
                        return <span className="badge badge-soft-secondary"> {row?.application?.status || '-'} </span>;
                    case "Closed":
                        return <span className="badge badge-soft-danger"> {row?.application?.status || '-'} </span>;
                    case "In-Progress":
                        return <span className="badge badge-soft-warning"> {row?.application?.status || '-'} </span>;
                    case "Open":
                        return <span className="badge badge-soft-primary"> {row?.application?.status || '-'} </span>;
                    case "New":
                        return <span className="badge badge-soft-success"> {row?.application?.status || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-success"> {row?.application?.status || '-'} </span>;
                }
            },
        }
    ];

    return data;
}

export default columns;
