import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Partners from '../../Pages/Admin/Partners/Partners';
import Details from '../../Pages/Admin/Partners/Details';

const Partner = () => {
  return (
    <Routes>
        <Route path='/list' element={<Partners />} />
        <Route path='/details/:partnerCode' element={<Details />} />
    </Routes>
  )
}

export default Partner;
