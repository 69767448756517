import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import useClass from "../../../hooks/useClass";
import LoadingButton from "../../../components/Common/LoadingButton";
import { useSelector } from "react-redux";

const PortalUserDetail = () => {
  const { adminServer, toaster } = useClass();
  const [loading, setLoading] = useState(true);
  const [portalUser, setPortalUser] = useState({});
  const user = useSelector((state) => state?.userDetails?.details);

  const { userId } = useParams();
  useEffect(() => {
    setLoading(true);
    getPortalUser(userId);
  }, []);

  const getPortalUser = async (userId) => {
    try {
      const response = await adminServer.getPortalUser(userId);
      const data = response?.data?.data;
      setLoading(false);
      setPortalUser(data);
    } catch (error) {
      setLoading(false);
      toaster.show(true, error?.message);
    }
  };

  const updatePortalUser = (userId) => {};

  return (
    <div>
      <Row className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="portal_users" breadcrumbItem="Portal User Details" />
          <Row>
            <Col xl={12}>
              <Card className="common-card-style">
                <CardHeader>
                  <div className="section-heading">Portal User Details</div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Name</Row>
                      <Row className="data-value me-4">{portalUser?.name}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Email</Row>
                      <Row className="data-value me-4">{portalUser?.email}</Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Customer Code</Row>
                      <Row className="data-value me-4">
                        {portalUser?.customerCode}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Country Code</Row>
                      <Row className="data-value me-4">
                        {portalUser?.countryCode}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Mobile No.</Row>
                      <Row className="data-value me-4">
                        {portalUser?.mobile}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created By</Row>
                      <Row className="data-value me-4">
                        {portalUser?.createdBy}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Created At</Row>
                      <Row className="data-value me-4">
                        {new Date(portalUser?.createdAt).toLocaleDateString()}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Is Active</Row>
                      <Row className="data-value me-4">
                        {portalUser?.isActive ? "Active" : "Disabled"}
                      </Row>
                    </div>
                    <div className="d-flex justify-content-between mb-2 col-lg-4 col-md-6 col-sm-12">
                      <Row className="data-key ms-4">Partner Code</Row>
                      <Row className="data-value me-4">
                        {portalUser?.partnerCode}
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {user?.role === "admin" && (
            <Row className="m-4">
              <Col className="d-flex justify-content-center gap-4">
                <LoadingButton
                  color={"success"}
                  buttonContent={"Update Details"}
                  onClick={() => updatePortalUser(portalUser.id)}
                />
              </Col>
            </Row>
          )}
        </Container>
      </Row>
    </div>
  );
};

export default PortalUserDetail;
