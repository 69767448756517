import Swal from "sweetalert2";

const imageViewStyle = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-danger"
  },
  buttonsStyling: false
})



export class Toaster {

  show(error = true, text = 'Something Went Wrong') {
    if (error) {
      Swal.fire({
        position: "bottom-right",
        icon: "error",
        // title: "Oops...",
        text: text,
        showConfirmButton: false,
        timer: 1500
      })
    } else {
      Swal.fire({
        position: "bottom-right",
        icon: "success",
        title: "Congratulations",
        text: text,
        showConfirmButton: false,
        timer: 1500
      })
    }
  }


  viewImage(img, height=400) {
    imageViewStyle.fire({
      imageUrl: img,
      imageHeight: height,
      imageWidth: 400,
      imageAlt: "Image",
      // background: "transparent"
    });
  }

  confirmation(confirmButtonText='Approve', text="You won't be able to revert this!"){
    return Swal.fire({
      title: 'Are you sure?',
      text: text,
      icon: "warning",
      // color: '#10B759',
      showCancelButton: true,
      confirmButtonText: confirmButtonText,
      cancelButtonText: "Cancel",
      reverseButtons: true,
      customClass: {
        container: 'custom-modal-container' // Add a custom class to the modal container
      }
    })
  }


  inputConfirmation() {
    return  Swal.fire({
        title: 'Enter Email',
        html: `
          <div>All the details of this application would be sent to the given email id.</div>
          <div>To: <input type="text" id="swal-input1" class="swal2-input" placeholder="Email"></div>
          <div>Cc: <input type="text" id="swal-input2" class="swal2-input" placeholder="Email"></div>
          <div>Cc: <input type="email" id="swal-input3" class="swal2-input" placeholder="Email"></div>
        `,
        focusConfirm: false,
        showCancelButton: true,
        cancelButtonText: "Cancel",
        preConfirm: () => {
          const toEmail = document.getElementById('swal-input1').value;
          const ccEmail1 = document.getElementById('swal-input2').value;
          const ccEmail2 = document.getElementById('swal-input3').value;
          if (!toEmail || !ccEmail1 || !ccEmail2) {
            Swal.showValidationMessage('Please enter all fields');
            return false;
          }
          return { toEmail, ccEmail1, ccEmail2 };
        }
      })
    }
  
}