export const generatePermissionsKeyPair = (permissions) => {
    let result = {};
    for(const module of permissions){
        let obj = {};
        for(const subModule of module?.subModules){
            obj = {
                ...obj,
                [subModule.subModuleCode]: subModule.permissions
            }
        }
        result = {
            ...result,
            [module.moduleCode]: obj
        }
    }

    return result;
}

export const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

export const validateFormAndCreatePayload = (form, prevData) => {
    let payload = {};
    const result = Object.keys(form)?.every((key) => {
      if(form?.[key] != prevData?.[key]){
        payload = {...payload, [key]: form?.[key]}
      }
      return form?.hasOwnProperty(key) && form?.[key] !== '';
    })

    return { result, payload }
}

export const validateForm = (form) => {
    const result = Object.keys(form || {})?.every((key) => {
      return form?.hasOwnProperty(key) && form?.[key] !== '';
    })
    return result;
}

function isObject(value) {
    return value && typeof value === 'object' && !Array.isArray(value);
}

export function validateNestedObject(obj, arr) {

  let result = true;

  for(let field of arr) {
    let fieldCode = field?.code;
    if(field?.subFields){
      for(let subField of field?.subFields){
        let subFieldCode = subField?.code?.split(' ')[1];
        if(subField?.childFields){
          for(let childField of subField?.childFields){
            let childFieldCode = childField?.code?.split(' ')[2];
            if(obj?.[fieldCode]?.[subFieldCode]?.[childFieldCode] === '' || obj?.[fieldCode]?.[subFieldCode]?.[childFieldCode] === undefined) result = false;
            if(!result) break;
          }
        } else {
          if(obj?.[fieldCode]?.[subFieldCode] === '' || obj?.[fieldCode]?.[subFieldCode] === undefined) result = false;
        }
        if(!result) break;
      }
    } else {
      if(obj?.[fieldCode] === '' || obj?.[fieldCode] === undefined) result = false;
    }
    if(!result) break;
  }
  
  return result; // Valid if all keys have values
}