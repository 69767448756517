import React from 'react';
import { Link } from 'react-router-dom';

const columns = [
    {
        name: <span className='font-weight-bold fs-13'>SR No.</span>,
        selector: row => row.index,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Log Id</span>,
        selector: row => (<Link to={`/api_logs/details/${row?.id}`}>{row.id}</Link>),
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>User Id</span>,
        selector: row => row.userId,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Customer Code</span>,
        selector: row => row.customerCode,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Log</span>,
        selector: row => (<Link to={`/api_logs/details/${row?.id}`}>{"see log details"}</Link>),
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Request Code</span>,
        selector: row => row.requestCode,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Request Method</span>,
        selector: row => row.requestMethod,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Request Type</span>,
        selector: row => row.requestType,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Request Url</span>,
        selector: row => row.requestUrl,
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Request Date</span>,
        selector: row =>new Date(row?.requestedAt).toLocaleDateString(),
        sortable: false
    },
    {
        name: <span className='font-weight-bold fs-13'>Response Status Code</span>,
        selector: row => row.responseStatusCode,
        sortable: false
    }
];

export default columns;
