import React, { useEffect, useState, memo } from 'react';
import DateRange from './DateRange';
import { Button } from 'reactstrap';

const Filters = ({ showCreateButton, onCreateButtonClick, fetchDataFromServer, pagination, setPagination}) => {

    const [dateRange, setDateRange] = useState({});
    const [searchText, setSearchText] = useState('');
    useEffect(() => {
        let timeOut = setTimeout(() => {
            fetchDataFromServer({pagination, searchText, dateRange});
        }, 500);
        return () => {
            clearTimeout(timeOut)
        }
    }, [dateRange, searchText, pagination]);

    const handleDateRange = (identifier, range) => {
        const {fromDate, toDate} = range;
        setDateRange({fromDate, toDate});
    }

    const handleSearch = (e) => {
        setPagination({page: 1, perPage: 20})
        setSearchText(e?.target?.value);
    }


    return (
        <>
            <div className='d-flex gap-4'>
                <div>
                    <DateRange
                        identifier={'date_range'}
                        handleInput={handleDateRange}
                    />
                </div>
                <div>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        onChange={handleSearch}
                    />
                </div>
                {showCreateButton && <div>
                    <Button color="success" onClick={onCreateButtonClick}>
                        <i className='bx bx-plus-medical' /> Create
                    </Button>
                </div>}
            </div>
        </>
    )
}

export default memo(Filters);
