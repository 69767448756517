import React, { useState } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { agreementType } from '../../../utils/constants';

const Create = (props) => {

  const { toaster, adminServer, permission, navigate } = useClass();
  const {isOpen, setIsOpen, selectedTemplated} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    type: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
        ...prev,
        [key]: value
    }))
  }

  const handleAgreementCreation = async () => {
    try{
      setIsLoading(true);
      if(validateForm()){
        const response = await adminServer.createNewAgreement({type: form?.type?.value, code: selectedTemplated});
        setIsLoading(false);
        if(response?.data?.success){
          handleModelClose();
          if(permission?.AGREEMENT?.DETAILS?.VIEW_AGREEMENT_DETAILS){
            navigate(`/agreement/details/${response?.data?.data?.code}`);
          } else {
            window.location.reload();
            toaster.show(false, 'Task created successfully');
          }
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please Fill all fields');
      }
    } catch(error){
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  }

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Select Agreement TYpe
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div className='mt-4'>
                <Dropdown
                  label={'Type'}
                  options={agreementType}
                  handleInput={handleInput}
                  required={true}
                  value={form?.type}
                  name={'type'}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleAgreementCreation}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default Create;
