import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChargingStations from '../../Pages/Oem/ChargingStation/Oem';
import Details from '../../Pages/Oem/ChargingStation/Details';

const ChargingStation = () => {
  return (
    <Routes>
        <Route path='/list' element={<ChargingStations />} />
        <Route path='/details/:batteryId' element={<Details />} />
    </Routes>
  )
}

export default ChargingStation;
