import React from 'react';
import { Modal } from "reactstrap";

const DocModal = (props) => {

  const {
    isOpen, 
    setIsOpen,
    size,
    label,
    link
  } = props;

  const handleModelClose = () => {
    setIsOpen(false);
  }

  return (
    <>
      <Modal 
        isOpen={isOpen}
        size={'xl'}
        toggle={() => {setIsOpen(!isOpen)} }
        centered
        className='modal-fullscreen'
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">
            {label}
          </h5>
          <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <iframe src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURI(link)}`} style={{width: '100%', height: '100%'}} alt="Documents"/>

        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleModelClose}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}

export default DocModal;
