import React, { useEffect, useState } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { getLenderList, getPartnerList } from '../../../store/admin/adminData';
import { useSelector } from 'react-redux';
  

const CreateUser = (props) => {

  const { toaster, adminServer, navigate, permission, dispatch } = useClass();
  const {isOpen, setIsOpen} = props;
  const partnerList = useSelector((state) => (state?.partner?.list));
  const lenderList = useSelector((state) => (state?.lender?.list));
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    type: "",
    org: ""
  });

  useEffect(() => {
    if(permission?.LENDERS?.LIST?.VIEW_LENDER_LIST_ALL && permission?.PARTNERS?.LIST?.VIEW_PARTNER_LIST_ALL){
      dispatch(getLenderList());
      dispatch(getPartnerList());
    }
  }, [])

  const handleInput = (key, value) => {
    setForm((prev) => ({
      ...prev,
      [key]: value
    }))
  }

  const handleDropdown = (key, value) => {
    if(key === 'type' && value.label === 'TAPFIN'){
      setForm((prev) => ({
        ...prev,
        [key]: value,
        org: value
      }))
    } else {
      setForm((prev) => ({
        ...prev,
        [key]: value
      }))
    }
  }

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleUserCreation = async () => {
    try{
      setIsLoading(true);
      const payload = {...form, type: form?.type?.value, org: form?.org?.value}
      if(validateForm(payload)){
        const response = await adminServer.createNewUser(payload);
        setIsLoading(false);
        if(response?.data?.success){
          handleModelClose();
          navigate(`/users/details/${response?.data?.user?.id}`);
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please Fill Details.');
      }
    } catch(error){
      setIsLoading(false);
      toaster.show(true, error?.message);
    }
  }

  const validateForm = (payload) => {
    const result = Object.keys(payload).every((key) => {
      return payload.hasOwnProperty(key) && payload[key].trim() !== '';
    });
    return result;
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create User
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div>
                <TextInput
                  label={'User Name'}
                  value={form?.name}
                  required={true}
                  type={'text'}
                  name={'name'}
                  handleInput={handleInput}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'User email'}
                  value={form?.email}
                  required={true}
                  type={'email'}
                  name={'email'}
                  handleInput={handleInput}
                />
              </div>
              <div className='mt-4'>
                <TextInput
                  label={'Password'}
                  value={form?.password}
                  required={true}
                  type={'text'}
                  name={'password'}
                  handleInput={handleInput}
                />
              </div>
              <div className='mt-4'>
                <Dropdown 
                  label={'User Type'}
                  value={form?.type}
                  required={true}
                  name={'type'}
                  options={[{label: 'TAPFIN', value: 'TAPFIN'}, {label: 'LENDER', value: 'LENDER'}, {label: 'PARTNER', value: 'PARTNER'}]}
                  handleInput={handleDropdown}
                />
              </div>
              {form?.type && <div className='mt-4'>
                { form?.type?.label === 'TAPFIN' && 
                  <TextInput
                    label={'Organisation'}
                    required={true}
                    value={form?.org?.label}
                    disabled={true}
                  />
                }
                {
                  (form?.type?.label === 'PARTNER') && 
                  <Dropdown 
                    label={'Select Partner'}
                    required={true}
                    value={form?.org}
                    name={'org'}
                    options={partnerList?.map((obj) => ({label: obj?.code, value: obj?.code}))}
                    handleInput={handleDropdown}
                  />
                }
                {
                  (form?.type?.label === 'LENDER') && 
                  <Dropdown 
                    label={'Select Lender'}
                    required={true}
                    value={form?.org}
                    name={'org'}
                    options={lenderList?.map((obj) => ({label: obj?.code, value: obj?.code}))}
                    handleInput={handleDropdown}
                  />
                }
              </div>}
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={handleUserCreation}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default CreateUser;
