import React, { useState } from 'react';
import { Badge, Button } from 'reactstrap';
import { reKycComments } from '../../../constants/constants';
import { applicationData, entityData, entityDirectorsData, entityDocumentsData } from '../../../utils/constants';
import MultiSelect from '../../../components/Dropdown/Multiselect';
import LoadingButton from '../../../components/Common/LoadingButton';
import useClass from '../../../hooks/useClass';
import { useSelector } from 'react-redux';
import TextInput from '../../../components/text-input/TextInput';
import Dropdown from '../../../components/Dropdown/Dropdown';

const Rekyc = (props) => {

    const {
        reKycFields, 
        handleRekycFieldsSelection, 
        handleModelClose, 
        isLoading,
        customerCode,
        handleFieldAction,
        handleAction
    } = props;

    const { toaster } = useClass();
    const { action, loanUid, productCode, applicationId } = useSelector((state) => (state?.loanAction?.data));
    const { taskFields } = useSelector((state) => (state?.actionFields));
    const [form, setForm] = useState(reKycFields);
    const [taskFieldsData, setTaskFieldsData] = useState({});

    const handleInput = (key, value) => {
        const part = key?.split(' ');
        setForm((prev) => ({
            ...prev,
            [part[1]]: {
                ...prev?.[part[1]],
                [part[0]]: value
            }
        }))
    }

    const handleTaskInput = (key, value) => {
        const part = key?.split(' ');
        setTaskFieldsData((prev) => ({
            ...prev,
            [part[1]]: {
                ...prev?.[part[1]],
                [part[0]]: value
            }
        }))
    }

    const handleSubmit = () => {
        if(validateForm()){
            let array = [];
            Object.keys(form)?.forEach((key) => {
                array = [...array, {fieldCode: key, reason: form?.[key]?.reason[0]?.label}]
            })
            let payload = {
                action, loanUid,
                comment: form
            }
            if(array.length > 0){
                payload.rekyc = {
                    customerCode, productCode, applicationId,
                    fields: array
                }
            }
            let taskArray = [];
            Object.keys(taskFieldsData)?.forEach((key) => {
                taskArray = [...taskArray, {
                    name: taskFieldsData?.[key]?.reason[0]?.label,
                    description: taskFieldsData?.[key]?.comment,
                    parentTaskCode: '',
                    type: taskFieldsData?.[key]?.type?.value,
                    field: key
                }]
            })
            if(taskArray.length > 0){
                payload.task = {
                    applicationId: applicationId,
                    customerCode,
                    data: taskArray
                }
            }
            handleAction(payload);
        } else {
            toaster.show(true, 'Please fill all');
        }
    }

    const validateForm = () => {
        const result = Object.keys(form).every((key) => {
            const keyCheck = form?.hasOwnProperty(key) && form[key] !== "";
            const reasonCheck = form?.[key]?.hasOwnProperty('reason') && form?.[key]?.reason !== '';
            const commentCheck = form?.[key]?.hasOwnProperty('comment') && form?.[key]?.comment !== '';
            return keyCheck && reasonCheck && commentCheck;
        });
        const result2 = Object.keys(taskFields).every((key) => {
            const keyCheck = taskFieldsData?.hasOwnProperty(key) && taskFieldsData[key] !== "";
            const reasonCheck = taskFieldsData?.[key]?.hasOwnProperty('reason') && taskFieldsData?.[key]?.reason !== '';
            const commentCheck = taskFieldsData?.[key]?.hasOwnProperty('comment') && taskFieldsData?.[key]?.comment !== '';
            const typeCheck = taskFieldsData?.[key]?.hasOwnProperty('type') && taskFieldsData?.[key]?.type !== '';
            return keyCheck && reasonCheck && commentCheck && typeCheck;
        });
        return result && result2;
    }
 
    return (
        <>
            <div className="modal-body">
                <div className='d-flex justify-content-between gap-2'>
                    <div className='col-1'>Field</div>
                    <div className='col-3'>Reason</div>
                    <div className='col-3'>Comment</div>
                    <div className='col-3'>Type</div>
                </div>
                {Object.keys(form || {})?.map((key, index) => (
                    <div className='d-flex justify-content-between gap-2 align-items-center' key={index}>
                        <div className='col-1'>
                            <Badge color='danger text-wrap'>
                                {entityData?.[key] || entityDirectorsData?.[key] || applicationData?.[key] || entityDocumentsData?.[key]} 
                            </Badge>
                        </div>
                        <div className='col-3'>
                            <MultiSelect
                                value={form?.[key]?.reason}
                                options={reKycComments?.[key]?.map((item) => ({label: item, value: item}))}
                                handleInput={handleInput}
                                name={`reason ${key}`}
                            />
                        </div>
                        <div className='col-3'>
                            <div className='d-flex gap-2 align-items-center'>
                                <TextInput
                                    value={form?.[key]?.comment}
                                    handleInput={handleInput}
                                    name={`comment ${key}`}
                                /> 
                                <Button close onClick={() => handleRekycFieldsSelection(key, false)}/>
                            </div>
                        </div>
                        <div className='col-3'>
                        </div>
                    </div>
                ))}
                {Object.keys(taskFields || {})?.map((key, index) => (
                    <>
                        <div className='d-flex justify-content-between gap-2 align-items-center' key={`task${index}`}>
                            <div className='col-1'>
                                <Badge color='warning text-wrap'>
                                    {entityData?.[key] || entityDirectorsData?.[key] || applicationData?.[key] || entityDocumentsData?.[key]} 
                                </Badge>
                            </div>
                            <div className='col-3'>
                                <MultiSelect
                                    value={taskFieldsData?.[key]?.reason}
                                    options={reKycComments?.[key]?.map((item) => ({label: item, value: item}))}
                                    handleInput={handleTaskInput}
                                    name={`reason ${key}`}
                                />
                            </div>
                            <div className='col-3'>
                                <div className='d-flex gap-2 align-items-center'>
                                    <TextInput
                                        value={taskFieldsData?.[key]?.comment}
                                        handleInput={handleTaskInput}
                                        name={`comment ${key}`}
                                    /> 
                                </div>
                            </div>
                            <div className='col-3'>
                                <div className='d-flex gap-2 align-items-center'>
                                    <Dropdown
                                        customClass={'w-100'}
                                        value={taskFieldsData?.[key]?.type}
                                        handleInput={handleTaskInput}
                                        options={[{label: 'Customer Visit', value: 'CUSTOMER_VISIT'},{label: 'Verification', value: 'VERIFICATION'}]}
                                        name={`type ${key}`}
                                    />
                                    <Button style={{marginTop: '-10px'}} close onClick={() => handleFieldAction({code: 'DELETE'}, key)}/>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
            <div className="modal-footer">
                <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                    Close
                </button>
                {<LoadingButton
                    buttonContent={'Submit'}
                    isLoading={isLoading}
                    onClick={handleSubmit}
                    color={'success'}
                />}
            </div>
        </>
    )
}

export default Rekyc;
