import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import RadialChart1 from "./userpanelChart1";
import RadialChart2 from "./userpanelChart2";
import RadialChart3 from "./userpanelChart3";

const UserPanel = ({dashboardData}) => {

  const calculatePercentageDifference = (today, yesterday) => {
    today = Number(today);
    yesterday = Number(yesterday);
    if (yesterday === 0) {
      return today === 0 ? 0 : (today > 0 ? 100 : 0);
    }
    return ((today - yesterday) / yesterday * 100).toFixed(2);
  };

  const totalCustomerToday = dashboardData?.totalCustomerToday || 0
  const totalCustomerYesterday = dashboardData?.totalCustomerYesterday || 0
  const totalJourneyCompletedToday = dashboardData?.totalJourneyCompletedToday || 0
  const totalJourneyCompletedYesterday = dashboardData?.totalJourneyCompletedYesterday || 0
  const totalPreviosDayLead = dashboardData?.totalPreviosDayLead || 0
  const totalNewLeads = dashboardData?.totalNewLeads || 0

  const customerPercentageDiff = calculatePercentageDifference(totalCustomerToday, totalCustomerYesterday);
  const journeyCompletedPercentageDiff = calculatePercentageDifference(totalJourneyCompletedToday, totalJourneyCompletedYesterday);
  const leadsPercentageDiff = calculatePercentageDifference(totalNewLeads, totalPreviosDayLead);
  
  return (
    <>
      <Row>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div id="radialchart-1" className="apex-charts" dir="ltr">
                    <RadialChart1 />
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Total Leads</p>
                  <h5 className="mb-3">{dashboardData?.totalLeads}</h5>
                  <p className="text-truncate mb-0">
                  <span className={leadsPercentageDiff<0?"text-danger me-2":"text-success me-2"}>
                      {" "}
                      {leadsPercentageDiff}%{" "}
                      <i className={leadsPercentageDiff<0?
                         "ri-arrow-right-down-line align-bottom ms-1":
                         "ri-arrow-right-up-line align-bottom ms-1"}></i>
                    </span>{" "}
                    From previous 
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <RadialChart2
                    id="radialchart-2"
                    className="apex-charts"
                    dir="ltr"
                    parcentage={Math.round((dashboardData?.totalCustomer/dashboardData?.totalLeads)*100)}
                  />
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Customers</p>
                  <h5 className="mb-3">{dashboardData?.totalCustomer}</h5>
                  <p className="text-truncate mb-0">
                    <span className={customerPercentageDiff<0?"text-danger me-2":"text-success me-2"}>
                      {" "}
                      {customerPercentageDiff}%{" "}
                      <i className={customerPercentageDiff<0?
                         "ri-arrow-right-down-line align-bottom ms-1":
                         "ri-arrow-right-up-line align-bottom ms-1"}></i>
                    </span>{" "}
                    From previous
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                <RadialChart3
                    id="radialchart-3"
                    className="apex-charts"
                    dir="ltr"
                    parcentage={Math.round((dashboardData?.totalJourneyCompleted/dashboardData?.totalLeads)*100)}
                  />
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">Journey Completed</p>
                  <h5 className="mb-3">{dashboardData?.totalJourneyCompleted}</h5>
                  <p className="text-truncate mb-0">
                    <span className={journeyCompletedPercentageDiff<0?"text-danger me-2":"text-success me-2"}>
                      {" "}
                      {journeyCompletedPercentageDiff}%{" "}
                      <i className={journeyCompletedPercentageDiff<0?
                         "ri-arrow-right-down-line align-bottom ms-1":
                         "ri-arrow-right-up-line align-bottom ms-1"}></i>
                    </span>{" "}
                    From previous
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col xl={3} sm={6}>
          <Card>
            <CardBody>
              <div className="d-flex text-muted">
                <div className="flex-shrink-0 me-3 align-self-center">
                  <div className="avatar-sm">
                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                      <i className="ri-group-line"></i>
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <p className="mb-1">New Leads</p>
                  <h5 className="mb-3">{dashboardData?.totalNewLeads}</h5>
                  <p className="text-truncate mb-0">
                    <span className={leadsPercentageDiff<0?"text-danger me-2":"text-success me-2"}>
                      {" "}
                      {leadsPercentageDiff}%{" "}
                      <i className={leadsPercentageDiff<0?
                         "ri-arrow-right-down-line align-bottom ms-1":
                         "ri-arrow-right-up-line align-bottom ms-1"}></i>
                    </span>{" "}
                    From previous
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UserPanel;
