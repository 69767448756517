import { ApiService } from '../service/ApiService';
import { AdminService } from '../service/AdminService';
import { Toaster } from '../components/toaster/Toaster';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { OemService } from '../service/OemService';

const useClass = () => {

    const server = new ApiService();
    const oemServer = new OemService();
    const adminServer = new AdminService();
    const toaster = new Toaster();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const permission = useSelector((state) => (state?.permissionPair?.permissions));

    return { server, adminServer,oemServer, toaster, dispatch, navigate, location, permission };
 
}

export default useClass;
