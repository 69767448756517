import React from 'react';
import { Route, Routes } from 'react-router-dom';
import OnboardingSystem from '../../Pages/Admin/OnboardingSystem/OnboardingSystem';
import Create from '../../Pages/Admin/OnboardingSystem/Create';

const Onboarding = () => {
  return (
    <Routes>
        <Route path='/list' element={<OnboardingSystem />} />
        <Route path='/create' element={<Create />} />
    </Routes>
  )
}

export default Onboarding;
