import React, { useEffect, useState } from "react";
import RadialChart from "./RadialChart";
import { Card, CardBody, Col, Row } from "reactstrap";
import useClass from "../../hooks/useClass";
import { getDashboardCampaignData } from "../../store/dashboard/dashboard";
import { useSelector } from "react-redux";
import { monthData } from "../../constants/constants";

const SocialSource = () => {
   
  const { toaster, dispatch } = useClass();
  const year = new Date().getFullYear();
  const {dashboardCampaignData} = useSelector((store) => store?.dashboardData)

  const getDashBoardCampaign = async (month) => {
    dispatch(getDashboardCampaignData({month,year}))
  }
  
  const getCurrentMonthNumber = () => {
    const now = new Date();
    return now.getMonth() + 1; 
  }

  useEffect(() => {
    getDashBoardCampaign(getCurrentMonthNumber());
  }, []);

  const SocialSourceData = [
    {
      title: "Total lead",
      count: dashboardCampaignData?.totalCampaignLeadsCount,
      icon: 'fas fa-user'
    },
    {
      title: "Customer",
      count: dashboardCampaignData?.totalCampaignCustomersCount,
      icon: 'fas fa-user-edit'
    },
    {
      title: "Journey complete",
      count: dashboardCampaignData?.totalCompletedJourneyCampaignCount,
      icon: 'fas fa-user-check'
    }
  ]

  return (
    <>
      <Col xl={4} md={6}>
        <Card>
          <CardBody>
            <div className="d-flex  align-items-center">
              <div className="flex-grow-1">
                <h5 className="card-title">Campaign Numbers</h5>
              </div>
              <div className="flex-shrink-0">
                <select defaultValue={getCurrentMonthNumber()} onChange={(e)=>getDashBoardCampaign(e.target?.value)} className="form-select form-select-sm mb-0 my-n1">
                  {monthData?.map((item, key) => (
                    <option key={key} value={item?.number}>
                      {item?.month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <RadialChart dashboardCampaignData={dashboardCampaignData} />
            <Row>
              {SocialSourceData.map((item, key) => (
                <div key={key} className="col-4">
                  <div className="social-source text-center mt-3">
                    <div className="avatar-xs mx-auto mb-3">
                      <span
                        className={
                          "avatar-title rounded-circle font-size-18 bg-" +
                          item.bgcolor
                        }
                      >
                        <i className={item.icon + " text-white"}></i>
                      </span>
                    </div>
                    <h5 className="font-size-15">{item.title}</h5>
                    <p className="text-muted mb-0">{item.count}</p>
                  </div>
                </div>
              ))}
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default SocialSource;
