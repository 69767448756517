import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row?.index || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Model Name</span>,
            selector: row => (permission?.VEHICLE?.DETAILS?.VIEW_VEHICLE_DETAILS ? <Link to={`/vehicle/details/${row?.code}`}>{row?.modelName || '-'}</Link> : row?.modelName),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Variant Name</span>,
            selector: row => row?.variantName || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Battery Capacity</span>,
            selector: row => row?.batteryCapacity || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Energy Consuption</span>,
            selector: row => (row?.energyConsuption ) ,
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Range</span>,
            selector: row => row?.range || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        },

    ];

    return data;
}

export default columns;
