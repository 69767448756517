import { createSlice } from "@reduxjs/toolkit";

export const task = createSlice({
    name: 'taskList',
    initialState: {
      list: [],
      totalList: 0,
      loading: false,
      error: null
    },
    reducers: {
      getTaskList: (state, action) => {
        state.loading = true;
      },
      getTaskListSuccess: (state, action) => {
        state.loading = false;
        state.list = action?.payload?.data;
        state.totalList = action?.payload?.count;
      },
      getTaskListFailure: (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      }
    }
});



export const hotLead = createSlice({
  name: 'hotLead',
  initialState: {
    list: [],
    totalList: 0,
    loading: false,
    error: null
  },
  reducers: {
    getHotLeadList: (state, action) => {
      state.loading = true;
    },
    getHotLeadListSuccess: (state, action) => {
      state.loading = false;
      state.list = action?.payload?.data;
      state.totalList = action?.payload?.count;
    },
    getHotLeadListError: (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    }
  }
})

// actions
export const { 
  getTaskList, 
  getTaskListSuccess, 
  getTaskListFailure 
} = task?.actions;



export const { 
  getHotLeadList,
  getHotLeadListSuccess,
  getHotLeadListError
} = hotLead?.actions;

// reducers
export const taskListReducer = task?.reducer;
export const hotLeadReducer = hotLead?.reducer;

