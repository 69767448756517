import React, { useState } from 'react';
import { Modal } from "reactstrap";
import LoadingButton from '../../components/Common/LoadingButton';
import useClass from '../../hooks/useClass';
import TextInput from '../../components/text-input/TextInput';
import { useSelector } from 'react-redux';
import { generateToken } from '../../utils/encryption';

const Create = (props) => {

  const userDetails = useSelector((state) => (state?.userDetails?.details));
  const { toaster, server, navigate, permission } = useClass();
  const {isOpen, setIsOpen} = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false)
  const [form, setForm] = useState({
    sendMessage: false,
    leadSource: 'CMS',
    status: 'INCOMPLETE',
    name: '',
    email: '',
    mobile: ''
  });

  const handleModelClose = () => {
    setIsOpen(false);
  }

  const handleInput = (key, value) => {
    setForm((prev) => ({
        ...prev,
        [key]: value
    }))
  }

  const handleLeadCreation = async (redirect) => {
    try{
      if(redirect){
        setIsLoadingTwo(true)
        setIsLoading(false);
      }
      else {
        setIsLoadingTwo(false)
        setIsLoading(true);
      }
      if(validateForm()){
        const response = await server.createHotLead(form);
        setIsLoading(false);
        setIsLoadingTwo(false);
        if(response?.data?.success){
          handleModelClose();
          if(redirect){
            navigateToCos();
          } else {
            window.location.reload();
          }
          if(permission?.HOT_LEADS?.DETAILS?.VIEW_HOT_LEAD_DETAILS){
            // navigate(`/roles/details/${response?.data?.roleId}`);
            toaster.show(false, 'Hot lead created successfully');
          } else {
            toaster.show(false, 'Hot lead created successfully');
          }
        } else {
          throw new Error(response?.data?.message);
        }
      } else {
        throw new Error('Please Fill Few Details To Create HOT Lead.');
      }
    } catch(error){
      setIsLoading(false);
      setIsLoadingTwo(false);
      toaster.show(true, error?.message);
    }
  }

  const validateForm = () => {
    const result = Object.keys(form).every((key) => {
        return form.hasOwnProperty(key) && form[key] !== "";
    });
    return result;
  }

  const navigateToCos = () => {
    const cosUrl = process.env.REACT_APP_COS_URL;
    const {emailToken, mobileToken} = generateToken(form?.mobile, userDetails?.email);
    window.open(`${cosUrl}/login?e=${emailToken}&m=${mobileToken}`, '_blank');
  }

  return (
    <>
        <Modal isOpen={isOpen}
          toggle={() => {setIsOpen(!isOpen)} }
          centered
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                  Create Hot Lead
                </h5>
                <button type="button" onClick={handleModelClose} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div>
                <TextInput 
                  label={'Name'}
                  required={true}
                  name={'name'}
                  value={form?.name}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Email'}
                  required={true}
                  name={'email'}
                  value={form?.email}
                  handleInput={handleInput}
                  type={'text'}
                />
              </div>
              <div className='mt-4'>
                <TextInput 
                  label={'Mobile No.'}
                  required={true}
                  name={'mobile'}
                  value={form?.mobile}
                  handleInput={handleInput}
                  type={'text'}
                  maxLength={10}
                />
              </div>
              <div className='mt-2'>
                <input type='checkbox' value={form?.sendMessage} onChange={(event) => {handleInput('sendMessage', event.target.checked)}} />
                {" "}Send link to customer
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" onClick={handleModelClose} className="btn btn-secondary" data-dismiss="modal">
                Close
              </button>
              <LoadingButton
                buttonContent={'Create'}
                isLoading={isLoading}
                onClick={() => handleLeadCreation(false)}
                color={'success'}
              />
              <LoadingButton
                buttonContent={'Create and Assist'}
                isLoading={isLoadingTwo}
                onClick={() => handleLeadCreation(true)}
                color={'success'}
              />
            </div>
        </Modal>
    </>
  )
}

export default Create;
