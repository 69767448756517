import React from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import { formatDate } from '../../../utils/helper';

const columns = (permission, handleSelection) => {
    
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row?.index || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Code</span>,
            selector: row => (permission?.AGREEMENT?.DETAILS?.VIEW_AGREEMENT_DETAILS ? <Link to={`/agreement/details/${row?.code}`}>{row?.code || '-'}</Link> : row?.code),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Type</span>,
            selector: row => row?.type || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (`${row?.isActive}`) {
                    case "false":
                        return <span className="badge badge-soft-danger"> {`${row?.isActive}` || '-'} </span>;
                    case "true":
                        return <span className="badge badge-soft-success"> {`${row?.isActive}` || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-warning"> {row?.isActive || '-'} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Is Approved</span>,
            sortable: false,
            selector: (row) => {
                switch (`${row?.isApproved}`) {
                    case "false":
                        return <span className="badge badge-soft-danger"> {'false' || '-'} </span>;
                    case "true":
                        return <span className="badge badge-soft-success"> {'true' || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-warning"> {row?.isApproved || '-'} </span>;
                }
            },
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: false,
            cell: (row) => {
                return (
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn btn-soft-secondary btn-sm" tag="button">
                            <i className="ri-more-fill align-middle"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            {permission?.AGREEMENT?.LIST?.CREATE_NEW_AGREEMENT &&
                                <DropdownItem className='edit-item-btn' onClick={() => handleSelection(row?.code)}>
                                    <i className="ri-pencil-fill align-bottom me-2 text-muted" /> Copy and Create
                                </DropdownItem>
                            }
                            <DropdownItem>
                                <i className="ri-eye-fill align-bottom me-2 text-muted" /> View
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );
            },
        },  
    ];

    return data;
}

export default columns;
