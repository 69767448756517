import { createSlice } from "@reduxjs/toolkit";

export const loanAction = createSlice({
  name: 'loanAction',
  initialState: {
    data: {
      action: null,
      loanUid: null,
      productCode: null,
      applicationId: null,
    },
    loading:false,
    invoiceDetails:{},
  },
  reducers: {
    updateLoanAction: (state, action) => {
      state.data = action?.payload;
    },
    getInvoiceDetailsAction: (state, action) => {
      state.loading = true;
    },
    getInvoiceDetailsActionSuccess: (state, action) => {
      state.invoiceDetails = action?.payload;
      state.loading = false;
    },
    getInvoiceDetailsActionFailure: (state, action) => {
      state.loading = false;
    }

    
  }
});

export const { updateLoanAction, 
  getInvoiceDetailsAction, 
  getInvoiceDetailsActionSuccess, 
  getInvoiceDetailsActionFailure } = loanAction?.actions;

export const loanActionReducer = loanAction?.reducer;