import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row?.index || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => (permission?.MANUFACTURE?.DETAILS?.VIEW_MANUFACTURE_DETAILS ? <Link to={`/manufacture/details/${row?.code}`}>{row?.name || '-'}</Link> : row?.name),
            sortable: false
        },

        {
            name: <span className='font-weight-bold fs-13'>Contact Email</span>,
            selector: row => (row?.contactEmail),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Contact Number</span>,
            selector: row => row?.contactNumber || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Headquarter</span>,
            selector: row => row?.hqAddressh || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        },
    ];

    return data;
}

export default columns;
