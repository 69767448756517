import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Manufactures from '../../Pages/Oem/Manufacture/Oem';
import Details from '../../Pages/Oem/Manufacture/Details';


const Manufacture = () => {
  return (
    <Routes>
        <Route path='/list' element={<Manufactures />} />
        <Route path='/details/:manufactureId' element={< Details/>} />
    </Routes>
  )
}

export default Manufacture;
