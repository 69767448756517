const SidebarData = [
    {
        label: "Dashboard",
        moduleCode: "DASHBOARD",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        // issubMenubadge: true,
        isHasArrow: true,
        bgcolor: "bg-primary",
        // badgeValue: "3"
    },
    {
        label: 'Tasks',
        moduleCode: 'TASK',
        icon: 'fas fa-tasks',
        isHasArrow: true,
        url: '/task/list'
    },
    {
        label: 'Oems',
        moduleCode: 'OEM',
        icon: 'fas fa-industry',
        // isHasArrow: true,
        // url: '/oem/list',
        subItem: [
            {
                sublabel: "Manufacturer",
                subModuleCode: "MANUFACTURE",
                link: "/manufacture/list" 
            },
            { 
                sublabel: "Vehicle",
                subModuleCode: "VEHICLE",
                link: "/vehicle/list" 
            },
            {
                sublabel: "Battery",
                subModuleCode: "BATTERY",
                link: "/battery/list"
            },
            {
                sublabel: "Charging Station",
                subModuleCode: "CHARGING_STATION",
                link: "/charging_station/list"
            },
            {
                sublabel: "Charger",
                subModuleCode: "CHARGER",
                link: "/charger/list"
            },
        ],
    },
    {
        label: "Leads",
        moduleCode: "HOT_LEADS",
        icon: "ri-brush-line",
        isHasArrow: true,
        url: "/hot_leads/list",
    },
    {
        label: "Customers",
        moduleCode: "CUSTOMERS",
        icon: "fas fa-user-tie",
        isHasArrow: true,
        url: "/customers/list",
    },
    {
        label: "Loans",
        moduleCode: "LOANS",
        icon: "mdi mdi-email-outline",
        subItem: [
            { 
                sublabel: "Disbursed", 
                subModuleCode: "DISBURSED",
                link: "/disbursed/list" 
            },
            { 
                sublabel: "Pending", 
                subModuleCode: "PENDING",
                link: "/test" 
            }
        ],
    },
    {
        label: "Admin",
        moduleCode: "ADMIN",
        icon: "mdi mdi-briefcase-variant-outline",
        subItem: [
            {
                sublabel: "Roles",
                subModuleCode: "ROLES",
                link: "/roles/list" 
            },
            { 
                sublabel: "Users",
                subModuleCode: "USERS",
                link: "/users/list" 
            },
            {
                sublabel: "Teams",
                subModuleCode: "TEAMS",
                link: "/teams/list"
            },
            { 
                sublabel: "Products", 
                subModuleCode: "PRODUCTS",
                link: "/products/list" 
            },
            {
                sublabel: 'Product Scheme',
                subModuleCode: 'PRODUCT_SCHEME',
                link: '/product_scheme/list'
            },
            { 
                sublabel: "Partners/Vendors",
                subModuleCode: "PARTNERS",
                link: "/partners/list" 
            },
            { 
                sublabel: "Lenders",
                subModuleCode: "LENDERS",
                link: "/lenders/list" 
            },
            // { 
            //     sublabel: "Onboarding System",
            //     subModuleCode: "ONBOARDING_SYSTEM",
            //     link: "/onboarding-system/create" 
            // },
            {
                sublabel: 'Agreements',
                subModuleCode: 'AGREEMENT',
                link: '/agreement/list'
            },
            { 
                sublabel: "Portal Users",
                subModuleCode: "PORTAL_USERS",
                link: "/portal_users/list" 
            },
            { 
                sublabel: "API Logs",
                subModuleCode: "API_LOGS",
                link: "/api_logs/list" 
            }
        ],
    }
]
export default SidebarData;