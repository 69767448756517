import React from "react";
import { Route, Routes } from "react-router-dom";
import PortalUsers from "../../Pages/Admin/PortalUsers/PortalUsers";
import PortalUserDetail from "../../Pages/Admin/PortalUsers/PortalUserDetail";
const PortalUser = () => {
  return (
    <Routes>
      <Route path="/list" element={<PortalUsers />} />
      <Route path="/details/:userId" element={<PortalUserDetail />} />
    </Routes>
  );
};

export default PortalUser;
