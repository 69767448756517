import { createSlice } from "@reduxjs/toolkit";

let permissions = []

export const userDetails = createSlice({
  name: 'userDetails',
  initialState: {
    details: {}
  },
  reducers: {
    updateUserDetails: (state, action) => {
      state.details = action?.payload;
    }
  }
});

export const userPermissions = createSlice({
  name: "userPermissions",
  initialState: {
    permissions: permissions
  },
  reducers: {
    updateUserPermissions: (state, action) => {
      state.permissions = action?.payload
    }
  }
})

export const permissionsKeyPair = createSlice({
  name: "permissionsPair",
  initialState: {
    permissions: {}
  },
  reducers: {
    updatePermissionsPair: (state, action) => {
      state.permissions = action?.payload
    }
  }
})




// export actions
export const  { updateUserDetails } = userDetails?.actions;
export const { updateUserPermissions } = userPermissions?.actions;
export const { updatePermissionsPair } = permissionsKeyPair?.actions;


// export reducers
export const userDetailsReducer =  userDetails?.reducer;
export const userPermissionsReducer = userPermissions?.reducer;
export const permissionsKeyPairReducer = permissionsKeyPair?.reducer;

