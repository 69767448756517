import React from "react";
import { Link } from "react-router-dom";

const columns = (permission) => {
  const data = [
    {
      name: <span className="font-weight-bold fs-13">SR No.</span>,
      selector: (row) => row?.index,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">User Name</span>,
      selector: (row) => (
        permission?.PORTAL_USERS?.DETAILS?.VIEW_PORTAL_USER_DETAILS ? <Link to={`/portal_users/details/${row?.id}`}>{row?.name}</Link> : row?.name
      ),
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Customer Code</span>,
      selector: (row) => (
        <Link to={`/portal_users/details/${row?.id}`}>{row?.customerCode}</Link>
      ),
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Email ID</span>,
      selector: (row) => row?.email,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Mobile Number</span>,
      selector: (row) => row?.mobile,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Partner Code</span>,
      selector: (row) => row?.partnersCode,
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Is Active</span>,
      selector: (row) => row?.isActive ? "Active": "Not Active",
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Created At</span>,
      selector: (row) => new Date(row?.createdAt).toLocaleDateString(),
      sortable: false,
    },
    {
      name: <span className="font-weight-bold fs-13">Created By</span>,
      selector: (row) => row?.createdBy,
      sortable: false,
    },
  ];

  return data;
}

export default columns;
