import React from 'react';
import { Link } from 'react-router-dom';

const columns = (permission) => {
    const data = [
        {
            name: <span className='font-weight-bold fs-13'>SR No.</span>,
            selector: row => row?.index || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Name</span>,
            selector: row => (permission?.TASK?.DETAILS?.VIEW_TASK_DETAILS ? <Link to={`/task/details/${row?.id}`}>{row?.name || '-'}</Link> : row?.name),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Type</span>,
            selector: row => row?.type || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Code</span>,
            selector: row => row?.code || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Application ID</span>,
            selector: row => (row?.applicationId),
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Assigned To</span>,
            selector: row => row?.assignedtoname || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Create Date</span>,
            selector: row => new Date(row?.createdAt).toLocaleDateString() || '-',
            sortable: false
        },
        {
            name: <span className='font-weight-bold fs-13'>Status</span>,
            sortable: false,
            selector: (row) => {
                switch (row?.status) {
                    case "OPEN":
                        return <span className="badge badge-soft-info"> {row?.status || '-'} </span>;
                    case "PENDING":
                        return <span className="badge badge-soft-secondary"> {row?.status || '-'} </span>;
                    case "IN_PROGRESS":
                        return <span className="badge badge-soft-warning"> {row?.status || '-'} </span>;
                    case "COMPLETE":
                        return <span className="badge badge-soft-success"> {row?.status || '-'} </span>;
                    default:
                        return <span className="badge badge-soft-danger"> {row?.status || '-'} </span>;
                }
            },
        }
    ];

    return data;
}

export default columns;
