// yourSaga.js
import { call, put, takeEvery, all, fork, takeLatest } from 'redux-saga/effects';
import { 
    addLenderNewAddress,
    addLenderNewAddressSuccess,
    addMemberInTeam,
    addMemberInTeamError,
    addMemberInTeamSuccess,
    approveAgreement,
    approveAgreementError,
    approveAgreementSuccess,
    getAgreementDetails,
    getAgreementDetailsError,
    getAgreementDetailsSuccess,
    getAgreementList,
    getAgreementListError,
    getAgreementListSuccess,
    getLenderDetails,
    getLenderDetailsError,
    getLenderDetailsSuccess,
    getLenderExtraDetails,
    getLenderExtraDetailsSuccess,
    getLenderList, 
    getLenderListFailure, 
    getLenderListSuccess, 
    getPartnerList, 
    getPartnerListError, 
    getPartnerListSuccess,
    getPortalUserList,
    getPortalUserListError,
    getPortalUserListSuccess,
    getProductDetails,
    getProductDetailsError,
    getProductDetailsSuccess,
    getProductList,
    getProductListError,
    getProductListSuccess,
    getProductSchemeList,
    getProductSchemeListError,
    getProductSchemeListSuccess,
    getRoleList,
    getRoleListError,
    getRoleListSuccess,
    getSchemeDetails,
    getSchemeDetailsError,
    getSchemeDetailsSuccess,
    getTeamDetails,
    getTeamDetailsError,
    getTeamDetailsSuccess,
    getTeamList,
    getTeamListError,
    getTeamListSuccess,
    getUserList,
    getUserListError,
    getUserListSuccess,
    toggleMemberActivation,
    toggleMemberActivationError,
    toggleMemberActivationSuccess,
    updateAgreementDetails,
    updateAgreementDetailsError,
    updateAgreementDetailsSuccess,
    updateProductConfig,
    updateProductConfigError,
    updateProductConfigSuccess,
    updateSchemeConfig,
    updateSchemeConfigError,
    updateSchemeConfigSuccess,
    updateTeamDetails,
    updateTeamDetailsError,
    updateTeamDetailsSuccess,
} from './adminData';
import { AdminService } from '../../service/AdminService';
import { Toaster } from '../../components/toaster/Toaster';

const adminServer = new AdminService();
const toaster = new Toaster();

// Watcher saga: spawns a new fetchData task on each getLenderList
function* watchRoleList() {
    yield takeEvery(getRoleList.type, fetchRoleList);
}

function* watchLenderList() {
    yield takeEvery(getLenderList.type, fetchLenderList);
}

function* watchLenderDetails() {
    yield takeEvery(getLenderDetails.type, fetchLenderDetails);
}

function* watchLenderExtraDetails() {
    yield takeEvery(getLenderExtraDetails.type, fetchLenderExtraDetails);
}

function* watchAddLenderNewAddress() {
    yield takeEvery(addLenderNewAddress.type,sendLenderNewAddress)
}

function* watchPartnerList() {
    yield takeEvery(getPartnerList.type, fetchPartnerList);
}

function* watchUserList() {
    yield takeEvery(getUserList.type, fetchUserList)
}

function* watchTeamList() {
    yield takeEvery(getTeamList.type, fetchTeamList);
}

function* watchTeamDetails() {
    yield takeEvery(getTeamDetails.type, fetchTeamDetails);
}

function* watchAddMemberInTeam() {
    yield takeEvery(addMemberInTeam.type, sendMemberInTeam);
}

function* watchToggleMemberActivation() {
    yield takeEvery(toggleMemberActivation.type, sendMemeberActivation);
}

function* watchUpdateTeamDetails() {
    yield takeEvery(updateTeamDetails.type, sendTeamDetails);
}

function* watchProductSchemeList() {
    yield takeEvery(getProductSchemeList.type, fetchProductSchemeList);
}

function* watchSchemeDetails() {
    yield takeEvery(getSchemeDetails.type, fetchSchemeDetails);
}

function* watchUpdateSchemeConfig() {
    yield takeEvery(updateSchemeConfig.type, updateSchemeConfigDetails);
}

function* watchAgreementList() {
    yield takeEvery(getAgreementList.type, fetchAgreementList);
}

function* watchAgreementDetails() {
    yield takeEvery(getAgreementDetails.type, fetchAgreementDetails);
}

function* watchAgreementCongifUpdate(){
    yield takeEvery(updateAgreementDetails.type, updateAgreementConfigDetails);
}

function* watchAgreementApprove() {
    yield takeEvery(approveAgreement.type, approvePendingAgreement)
}

function* watchProductList() {
    yield takeEvery(getProductList.type, fetchProductList);
}

function* watchProductDetails() {
    yield takeEvery(getProductDetails.type, fetchProductDetails);
}

function* watchProductConfigUpdate() {
    yield takeEvery(updateProductConfig.type, sendProductConfig);
}

function* watchPortalUserList() {
    yield takeEvery(getPortalUserList.type, fetchPortalUserList);
}



//API
const getRoleListFromServer = async (payload) => (
    await adminServer.getRoleList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getLenderListFromServer = async (payload) => (
    await adminServer.getLenders(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((lenders) => (lenders)).catch((error) => (error))
)

const getLenderDetailsFromServer = async (payload) => (
    await adminServer.getLender(payload).then((response) => (response)).catch((error) => (error))
)

const getLenderExtraDetailsFromServer = async (payload) => (
    await adminServer.getLenderExtraDetails(payload).then((response) => (response)).catch((error) => (error))
)

const sendLenderNewAddressToServer = async (payload) => (
    await adminServer.addLenderNewAddress(payload?.lenderCode, payload?.payload).then((response) => (response)).catch((error) => (error))
)


const getPartnerListFormServer = async (payload) => (
    await adminServer.getPartners(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((partners) => (partners)).catch((error) => (error))
)

const getUserListFromServer = async (payload) => (
    await adminServer.getUserList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((users) => (users)).catch((error) => (error))
)

const getTeamListFromServer = async (payload) => (
    await adminServer.getTeamsList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getTeamDetailsFromServer = async (payload) => (
    await adminServer.getTeamDetails(payload).then((response) => (response)).catch((error) => (error))
)

const sendTeamDetailsToServer = async (payload) => (
    await adminServer.updateTeamDetails(payload?.teamId, payload?.payload).then((response) => (response)).catch((error) => (error))
)

const sendTeamMemberToServer = async (payload) => (
    await adminServer.addMemberInTeam(payload?.teamId, payload?.payload).then((response) => (response)).catch((error) => (error))
)

const sendMemberActivationToServer = async(payload) => (
    await adminServer.updateMemberActivation(payload?.teamId, payload?.payload).then((response) => (response)).catch((error) => (error))
)

const getProductSchemeListFromServer = async (payload) => (
    await adminServer.getProductSchemeList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getSchemeDetailsFromServer = async (payload) => (
    await adminServer.getSchemeDetails(payload).then((response) => (response)).catch((error) => (error)) 
)

const updateSchemeConfigToServer = async (payload) => (
    await adminServer.updateScheme(payload?.id, payload?.form).then((response) => (response)).catch((error) => (error))
)

const getAgreementListFromServer = async (payload) => (
    await adminServer.getAgreementList(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getAgreementDetailsFromServer = async (payload) => (
    await adminServer.getAgreementDetails(payload).then((response) => (response)).catch((error) => (error))
)

const updateAgreementDetailsToServer = async (payload) => (
    await adminServer.updateAgreement(payload?.payload, payload?.code).then((response) => (response)).catch((error) => (error))
)

const approveAgreementToServer = async (payload) => (
    await adminServer.approveAgreement(payload).then((response) => (response)).catch((error) => (error))
);

const getProductListFromServer = async (payload) => (
    await adminServer.getProducts(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)

const getProductDetailsFromServer = async (payload) => (
    await adminServer.getProduct(payload).then((response) => (response)).catch((error) => (error))
)

const sendProductConfigToServer = async (payload) => (
    await adminServer.updateProductConfig(payload?.productCode, payload?.payload).then((response) => (response)).catch((error) => (error))
)

const getPortalUserListFromServer = async (payload) => (
    await adminServer.getPortalUsers(payload?.page, payload?.perPage, payload?.search, payload?.dateRange?.fromDate, payload?.dateRange?.toDate).then((response) => (response)).catch((error) => (error))
)




// Worker saga: will be fired on getLenderList actions
function* fetchRoleList(action) {
    try {
        const response = yield call(getRoleListFromServer, action?.payload);
        if(response?.data?.success){
            const { roles, count } = response?.data;
            roles?.forEach((data, index) => {
              data.index = index + 1;
            });
            yield put(getRoleListSuccess({data:roles, count}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getRoleListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchLenderList(action) {
    try {
        const response = yield call(getLenderListFromServer, action?.payload);
        if(response?.data?.success){
            const {data,totalCount} = response?.data;
            data?.forEach((data, index) => {
              data.index = index + 1;
            });
            yield put(getLenderListSuccess({data, count: totalCount}));
        } else {
            throw new Error(response?.message);
        }
    } catch (error) {
        yield put(getLenderListFailure(error.message));
        toaster.show(true, error?.message);
    }
}

function* fetchLenderExtraDetails(action) {
    try {
        const response = yield call(getLenderExtraDetailsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getLenderExtraDetailsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getLenderDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendLenderNewAddress(action) {
    try {
        const response = yield call(sendLenderNewAddressToServer, action?.payload);
        if(response?.data?.success){
            toaster.show(false, 'Added Successfully');
            yield put(addLenderNewAddressSuccess());
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getLenderDetailsError(error?.message));
    }
}

function* fetchLenderDetails(action) {
    try {
        const response = yield call(getLenderDetailsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getLenderDetailsSuccess(response?.data?.data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getLenderDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchPartnerList(action) {
    try {
        const response = yield call(getPartnerListFormServer, action?.payload);
        if(response?.data?.success){
            const {data, totalCount} = response?.data;
            data.forEach((data, index) => {
              data.index = index + 1;
            });
            yield put(getPartnerListSuccess({data, count: totalCount}));
        } else{
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getPartnerListError(error?.message));
    }
}

function* fetchUserList(action) {
    try {
        const response = yield call(getUserListFromServer, action?.payload);
        if(response?.data?.success){
            const { data, totalCount } = response?.data?.data;
            const arr = data?.map((user, index) => ({...user, label: user?.name, value: user?.id, index: index + 1}));
            yield put(getUserListSuccess({data: arr, count: totalCount}));
            if(action?.payload?.callback){
                action?.payload?.callback(arr);
            }
        } else{
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getUserListError(error?.message));
    }
}

function* fetchTeamList(action) {
    try{
        const response = yield call(getTeamListFromServer, action?.payload);
        if(response?.data?.success){
            const { data, totalCount } = response?.data;
            const arr = data?.map((data, index) => ({...data, label: data?.name, value: data?.id, index: index+1}));
            yield put(getTeamListSuccess({data: arr, count: totalCount}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getTeamListError(error?.message));
    }
}

function* fetchTeamDetails(action) {
    try {
        const response = yield call(getTeamDetailsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getTeamDetailsSuccess(response?.data?.data?.[0]));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(getTeamDetailsError(error?.message));
    }
}

function* sendTeamDetails(action) {
    try {
        const response = yield call(sendTeamDetailsToServer, action?.payload);
        if(response?.data?.success){
            toaster.show(false, 'Updated Successfully');
            yield put(updateTeamDetailsSuccess());
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(updateTeamDetailsError(error?.message));
    }
}

function* sendMemeberActivation(action) {
    try {
        const response = yield call(sendMemberActivationToServer, action?.payload);
        if(response?.data?.success){
            toaster.show(false, 'Updated Successfully');
            yield put(toggleMemberActivationSuccess());
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(toggleMemberActivationError(error?.message));
    }
}

function* sendMemberInTeam(action) {
    try {
        const response = yield call(sendTeamMemberToServer, action?.payload);
        if(response?.data?.success){
            toaster.show(false, 'Updated Successfully');
            yield put(addMemberInTeamSuccess());
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(addMemberInTeamError(error?.message));
    }
}

function* fetchProductSchemeList(action) {
    try {
        const response = yield call(getProductSchemeListFromServer, action?.payload);
        if(response?.data?.success){
            const { data, count } = response?.data;
            data?.forEach((data, index) => {
              data.index = index + 1;
            });
            yield put(getProductSchemeListSuccess({data, count}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(getProductSchemeListError(error?.message));
    }
}

function* fetchSchemeDetails(action) {
    try{
        const response = yield call(getSchemeDetailsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getSchemeDetailsSuccess(response?.data?.data?.[0]));
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        toaster.show(true, error?.message);
        yield put(getSchemeDetailsError(error?.message));
    }
}

function* updateSchemeConfigDetails(action) {
    try {
        const response = yield call(updateSchemeConfigToServer, {form: action?.payload?.form, id: action?.payload?.code});
        if(response?.data?.success){
            yield put(updateSchemeConfigSuccess());
            toaster.show(false, response?.data?.message);
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        toaster.show(true, error?.message);
        yield put(updateSchemeConfigError(error?.message));
    }
}

function* fetchAgreementList(action) {
    try {
        const response = yield call(getAgreementListFromServer, action?.payload);
        if(response.data?.success){
            const { data, count } = response?.data;
            const arr = data?.map((data, index) => ({...data, label: data?.code, value: data?.id, index: index+1}));
            yield put(getAgreementListSuccess({data: arr, count}))
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getAgreementListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchAgreementDetails(action) {
    try {
        const response = yield call(getAgreementDetailsFromServer, action?.payload);
        if(response.data?.success){
            let data = response?.data?.data?.[0];
            yield put(getAgreementDetailsSuccess(data));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getAgreementDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* updateAgreementConfigDetails(action) {
    try {
        const response = yield call(updateAgreementDetailsToServer, action?.payload);
        if(response?.data?.success){
            yield put(updateAgreementDetailsSuccess());
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(updateAgreementDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* approvePendingAgreement(action) {
    try {
        const response = yield call(approveAgreementToServer, action?.payload);
        if(response.data?.success){
            yield put(approveAgreementSuccess());
            window.location.reload();
        } else {
            throw new Error(response?.message);
        }
    } catch(error) {
        yield put(approveAgreementError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchProductList(action) {
    try {
        const response = yield call(getProductListFromServer, action?.payload);
        if(response?.data?.success){
            const {data, totalCount} = response?.data;
            data.forEach((data, index) => {
              data.index = index + 1;
            });
            yield put(getProductListSuccess({data, count: totalCount}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getProductListError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchProductDetails(action) {
    try {
        const response = yield call(getProductDetailsFromServer, action?.payload);
        if(response?.data?.success){
            yield put(getProductDetailsSuccess(response?.data?.data?.[0]));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getProductDetailsError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* sendProductConfig(action) {
    try {
        const response = yield call(sendProductConfigToServer, action?.payload);
        if(response?.data?.success){
            yield put(updateProductConfigSuccess());
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(updateProductConfigError(error?.message));
        toaster.show(true, error?.message);
    }
}

function* fetchPortalUserList(action) {
    try {
        const response = yield call(getPortalUserListFromServer, action?.payload);
        if(response?.data?.success){
            const { data, totalCount } = response?.data;
            data.forEach((data, index) => {
              data.index = index + 1;
            });
            yield put(getPortalUserListSuccess({data, count: totalCount}));
        } else {
            throw new Error(response?.message);
        }
    } catch(error){
        yield put(getPortalUserListError(error?.message));
        toaster.show(true, error?.message);
    }
}




export default function* adminSaga(){
    yield all([
        fork(watchRoleList),
        fork(watchLenderList),
        fork(watchLenderDetails),
        fork(watchLenderExtraDetails),
        fork(watchAddLenderNewAddress),
        fork(watchPartnerList),
        fork(watchUserList),
        fork(watchTeamList),
        fork(watchTeamDetails),
        fork(watchUpdateTeamDetails),
        fork(watchAddMemberInTeam),
        fork(watchToggleMemberActivation),
        fork(watchProductSchemeList),
        fork(watchSchemeDetails),
        fork(watchUpdateSchemeConfig),
        fork(watchAgreementList),
        fork(watchAgreementDetails),
        fork(watchAgreementCongifUpdate),
        fork(watchAgreementApprove),
        fork(watchProductList),
        fork(watchPortalUserList),
        fork(watchProductDetails),
        fork(watchProductConfigUpdate)
    ])
};
