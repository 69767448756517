import React, { useEffect, useState } from 'react';
import { 
    Card, 
    CardBody, 
    CardHeader, 
    Spinner, 
    Row, 
    Nav, 
    NavItem, 
    NavLink, 
    TabContent, 
    TabPane, 
    Accordion, 
    AccordionBody, 
    AccordionItem, 
    AccordionHeader,
    Input,
    Button
} from 'reactstrap';
import useClass from '../../hooks/useClass';
import { 
    additionalDocList, 
    entityData, 
    entityDirectorsData, 
    entityDocumentsData, 
    fieldStatusConstants, 
    fieldVerificationDropdown, 
    reKycFieldData 
} from '../../utils/constants';
import DocModal from '../../components/Document-Viewer/DocModal';
import Dropdown from '../../components/Dropdown/Dropdown';
import ActionDropdown from '../../components/Dropdown/ActionDropdown';
import { useSelector } from 'react-redux';

const Entity = ({
    entityFinancialData, 
    isRekycOpen, 
    handleRekycFieldsSelection, 
    reKycFields, 
    handleFieldAction, 
    entityDetails
}) => {

    const { toaster } = useClass();
    const { fieldStatus } = useSelector((state) => (state?.actionFields));
    const [open, setOpen] = useState('');
    const [activeDirectorTab, setActiveDirectorTab] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [viewedDoc, setViewedDoc] = useState({});
    const [addtionalDoc, setAdditionalDoc] = useState([]);
    const [isAdditionalDocButtonClicked, setIsAdditionalDocButtonClicked] = useState(false);

    const toggleDirectorTab = (tab) => {
        return () => {
          if(activeDirectorTab !== tab){
            setActiveDirectorTab(tab);
          }
        }
    }

    const toggle = (id) => {
        if (open === id) {
          setOpen('');
        } else {
          setOpen(id);
        }
    };

    const handleImageExpand = (link, label) => {
        return () => {
            window.open(link, '_blank');
            // setViewedDoc({link, label});
            // setIsOpen(true);
        }
    }

    const handleAdditionDocInput = (key, value) => {
        if(!addtionalDoc.includes(value?.value)){
            setIsAdditionalDocButtonClicked(false);
            setAdditionalDoc((prev) => ([...prev, value?.value]))
            handleRekycFieldsSelection(value?.value, true);
        } else {
            toaster.show(true, 'Document already selected');
            setIsAdditionalDocButtonClicked(false);
        }
    }

    const handleAdditionalDocButton = (type, key=null) => {
        return () => {
            if(type === 'add'){
                setIsAdditionalDocButtonClicked(true);
            } else {
                setAdditionalDoc(addtionalDoc?.filter((item) => item !== key))
                handleRekycFieldsSelection(key, false);
            }
        }
    }

    return (
        <>
            <Card>
                <CardHeader>
                    <div className='section-heading'>Entity Details</div>
                </CardHeader>
                <CardBody>
                {/* { loading ? 
                    <>
                        <Spinner></Spinner>
                    </> 
                    : 
                    <> */}
                        <Row>
                            {Object.keys(entityDetails)?.map((key, index) => {
                                if(entityData[key]){
                                    return (
                                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>
                                            <div className='data-key ms-4 gap-4'>
                                                <span>
                                                    {isRekycOpen &&
                                                        <Input
                                                            style={{border: !(reKycFieldData?.includes(key) && key != 'pan') && 'none' }}
                                                            className='rekyc-input' 
                                                            type='checkbox'
                                                            onChange={(e) => {handleRekycFieldsSelection(key, e.target.checked)}}
                                                            disabled={!(reKycFieldData?.includes(key) && key != 'pan')}
                                                            checked={reKycFields?.[key]}
                                                        />
                                                    }
                                                    {entityData[key]}
                                                </span>
                                            </div>
                                            <div className='data-value me-4'>
                                                <span>{entityDetails?.[key] || '-'}</span>
                                                {reKycFieldData?.includes(key) && 
                                                    <ActionDropdown
                                                        color={fieldStatusConstants?.[fieldStatus?.[key]]?.color}
                                                        className={fieldStatusConstants?.[fieldStatus?.[key]]?.className}
                                                        options={fieldVerificationDropdown}
                                                        onClick={(action) => handleFieldAction(action, key)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            })}
                            {entityFinancialData?.map((obj, index) => {
                                if(entityData[obj?.fieldKey]){
                                    return (
                                        <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>                 
                                            <div className='data-key ms-4'>
                                                <span>
                                                    {(isRekycOpen) && 
                                                        <Input
                                                            style={{border: !reKycFieldData?.includes(obj?.fieldKey) && 'none'}}
                                                            className='rekyc-input' 
                                                            type='checkbox'
                                                            onChange={(e) => handleRekycFieldsSelection(obj?.fieldKey, e.target.checked)}
                                                            disabled={!reKycFieldData?.includes(obj?.fieldKey)}
                                                            checked={reKycFields?.[obj?.fieldKey]}
                                                        />
                                                    }
                                                    {entityData[obj?.fieldKey]}
                                                </span>
                                            </div>
                                            <div className='data-value me-4'>
                                                <span>{obj?.fieldValue || '-'}</span>
                                                {reKycFieldData?.includes(obj?.fieldKey) &&
                                                    <ActionDropdown
                                                        color={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.color}
                                                        className={fieldStatusConstants?.[fieldStatus?.[obj?.fieldKey]]?.className}
                                                        options={fieldVerificationDropdown}
                                                        onClick={(action) => handleFieldAction(action, obj?.fieldKey)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    
                                    )
                                }
                            })}
                            {entityDetails?.entityDoc?.length > 0 && entityDetails?.entityDoc?.map((obj, index) => (
                                <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>
                                    <div className='data-key ms-4'>
                                        <span>
                                            {(isRekycOpen) && 
                                                <Input
                                                    className='rekyc-input' 
                                                    type='checkbox'
                                                    onChange={(e) => handleRekycFieldsSelection(obj?.docType, e.target.checked)}
                                                    checked={reKycFields?.[obj?.docType]}
                                                />
                                            }
                                            {entityDocumentsData?.[obj?.docType]}
                                        </span>
                                    </div>
                                    <div className='data-value me-4'>
                                        <span onClick={handleImageExpand(obj?.presignUrl, entityDocumentsData?.[obj?.docType])} className='clickable'>
                                            <i className='fas fa-external-link-alt' />
                                        </span>
                                        <ActionDropdown
                                            color={fieldStatusConstants?.[fieldStatus?.[obj?.docType]]?.color}
                                            className={fieldStatusConstants?.[fieldStatus?.[obj?.docType]]?.className}
                                            options={fieldVerificationDropdown}
                                            onClick={(action) => handleFieldAction(action, obj?.docType)}
                                        />
                                    </div>
                                </div>
                            ))}
                            {addtionalDoc?.map((key, index) => (
                                <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}>
                                    <Dropdown
                                        value={{label: entityDocumentsData?.[key], value: key}}
                                        customClass='addition-doc-dropdown'
                                        options={additionalDocList}
                                        handleInput={handleAdditionDocInput}
                                        name={'additionalDoc'}
                                        isClearable={false}
                                    />
                                    <Button 
                                        onClick={handleAdditionalDocButton('remove', key)}
                                        className='btn-close addtional-doc-button-remove'
                                    />
                                </div>
                            ))}
                            <div className='d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                {isAdditionalDocButtonClicked ? <Dropdown
                                    customClass='addition-doc-dropdown'
                                    options={additionalDocList}
                                    handleInput={handleAdditionDocInput}
                                    name={'additionalDoc'}
                                /> :
                                <Button 
                                    onClick={handleAdditionalDocButton('add')} 
                                    className='addtional-doc-button'
                                    color='primary' 
                                    outline
                                >
                                    Request more doc
                                </Button>}
                            </div>
                        </Row>
                        {entityDetails?.entityDirectors?.length > 0 && 
                            <Row className='p-4'>
                                <Nav tabs>
                                    {entityDetails?.entityDirectors?.map((director, index) => (
                                        <NavItem key={index}>
                                            <NavLink 
                                                style={{color: activeDirectorTab === index && 'green' }} 
                                                className={`${activeDirectorTab === index && 'active'} clickable`} 
                                                onClick={toggleDirectorTab(index)}
                                            >
                                              <i className="ri-user-line me-1 align-middle"> </i>{" "}
                                              {director?.name}
                                              {director?.kycStatus === 'VERIFIED' && <i className='fas fa-check-circle'></i>}
                                            </NavLink>
                                        </NavItem>
                                    ))}
                                </Nav>
                                <TabContent activeTab={activeDirectorTab} className="p-3">
                                    <TabPane tabId={activeDirectorTab} id="home">
                                        <Row>
                                            {entityDetails?.entityDirectors && <>
                                                {Object.keys(entityDetails?.entityDirectors?.[activeDirectorTab])?.map((key, index) => {
                                                    if(entityDirectorsData[key]){
                                                        return (
                                                            <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12' key={index}> 
                                                                <div className='data-key ms-4'>
                                                                    <span>
                                                                        {(isRekycOpen) && 
                                                                            <Input 
                                                                                style={{border: !reKycFieldData?.includes(key) && 'none'}}
                                                                                className='rekyc-input'  
                                                                                type='checkbox'
                                                                                onChange={(e) => handleRekycFieldsSelection(key, e.target.checked)}
                                                                                disabled={!reKycFieldData?.includes(key)}
                                                                            />
                                                                        }
                                                                        {entityDirectorsData[key]}
                                                                    </span>
                                                                </div>
                                                                <div className='data-value me-4'>{entityDetails?.entityDirectors[activeDirectorTab]?.[key] || '-'}</div>
                                                            </div>
                                                        )
                                                    }
                                                })}
                                                {entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc && <div className='mb-2 d-flex justify-content-between col-lg-4 col-md-6 col-sm-12'>
                                                    <Accordion open={open} toggle={toggle} className='w-100'>
                                                        <AccordionItem>
                                                            <AccordionHeader targetId={'1'}>
                                                                Aadhaar 
                                                                <span onClick={() => handleImageExpand(entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc)} style={{marginLeft: "50%"}}>
                                                                    <i className="ri-fullscreen-line" />
                                                                </span>
                                                            </AccordionHeader>
                                                            <AccordionBody accordionId={`1`}>
                                                              <div className='w-100 mt-2'>
                                                                <iframe style={{width: "100%"}} src={entityDetails?.entityDirectors[activeDirectorTab]?.directorDoc} alt="aadhaar PDF" />
                                                              </div>
                                                            </AccordionBody>
                                                        </AccordionItem>
                                                    </Accordion>
                                                </div>}
                                            </>}
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Row>
                        }
                    {/* </>
                } */}
                </CardBody>
            </Card>
            <DocModal 
                isOpen={isOpen} 
                setIsOpen={setIsOpen}
                size={'xl'}
                label={viewedDoc?.label}
                link={viewedDoc?.link}
            />
        </>
    )
}

export default Entity;
