import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineColumnArea = ({label,dashboardMonthData}) => {

      const totalLeads = dashboardMonthData?.totalLeads;
      const totalCustomer = dashboardMonthData?.totalCustomer;
      const totalCompletedJourey = dashboardMonthData?.totalCompletedJourney;

     const formatDasboardMonthData = (data) => {
      const counts = Array(12).fill(0);
      data?.forEach(item => {
        const month = parseInt(item.month, 10); 
        const count = parseInt(item.count, 10); 
      
        // Subtract 1 from month to get the correct index (0-based)
        if (month >= 1 && month <= 12) {
          counts[month - 1] += count;
        }
      });

      return counts
     }

  const LineColumnAreaData = {

  
    series: [
      {
        name: "Total leads",
        type: "column",
        data: formatDasboardMonthData(totalLeads),
      },
      {
        name: "Customers",
        type: "area",
        data: formatDasboardMonthData(totalCustomer),
      },
      {
        name: "Journey completed",
        type: "line",
        data: formatDasboardMonthData(totalCompletedJourey),
      },
    ],
    options: {
      chart: {
        height: 350,
          type: 'line',
          stacked: false,
          toolbar: {
            show: false
          },
      },
      stroke: {
        width: [0, 1, 1],
          dashArray: [0, 0, 5],
          curve: 'smooth'
      },
      plotOptions: {
        bar: {
          columnWidth: "18%",
        },
      },
      legend: {
        show: false,
      },
      colors: ["#0ab39c", "rgba(212, 218, 221, 0.18)", "rgb(251, 77, 83)"],
  
      fill: {
        opacity: [0.85, 0.25, 1],
        gradient: {
          inverseColors: false,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: label,
      markers: {
        size: 0,
      },
      xaxis: {
        type: "month",
      },
      tooltip: {
        shared: true,
        intersect: false,
        // y: {
        //   formatter: function (y) {
        //     if (typeof y !== "undefined") {
        //       return y.toFixed(0) + " points"
        //     }
        //     return y
        //   },
        // },
      },
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  }

  return(
    <>
      <ReactApexChart
        options={LineColumnAreaData.options}
        series={LineColumnAreaData.series}
        type="line"
        height="350"
        stacked= "false"
        className="apex-charts"
      />
    </>
  )
}

export default LineColumnArea;