export const permissionMapper = {
    'dashboard': {
    
    },
    'customers': {
        list: {
            view: 'VIEW_CUSTOMER_LIST'
        },
        details: {
            view: 'VIEW_CUSTOMER_DETAILS',
        }
    },
    'roles': {
        list: {
            view: 'VIEW_ROLE_LIST'
        },
        details: {
            view: 'VIEW_ROLE_DETAILS'
        }
    },
    'users': {
        list: {
            view: 'VIEW_USER_LIST'
        },
        details: {
            view: 'VIEW_USER_DETAILS'
        }
    },
    'products': {
        list: {
            view: 'VIEW_PRODUCT_LIST'
        },
        details: {
            view: 'VIEW_PRODUCT_DETAILS'
        }
    },
    'lenders': {
        list: {
            view: 'VIEW_LENDER_LIST'
        },
        details: {
            view: 'VIEW_LENDER_DETAILS'
        }
    },
    'partners': {
        list: {
            view: 'VIEW_PARTNER_LIST'
        },
        details: {
            view: 'VIEW_PARTNER_DETAILS'
        }
    },
    'onboarding-system': {
        list: {
            view: 'VIEW_ONBOARDING_SYSTEM_LIST'
        },
        details: {
            view: 'VIEW_ONBOARDING_SYSTEM_DETAILS'
        },
        create: {
            
        }
    },
    'api-logs': {
        list: {
            view: 'VIEW_API_LOG'
        },
        details: {
            view: 'VIEW_API_LOG_DETAILS'
        }
    },
    'portal-users': {
        list: {
            view: 'VIEW_PORTAL_USERS'
        },
        details: {
            view: 'VIEW_PORTAL_USER_DETAILS'
        }
    }
}