import React from 'react';

const OnboardingSystem = () => {
  return (
    <div>
      
    </div>
  )
}

export default OnboardingSystem;
